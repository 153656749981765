<template>
    <div class="wrapper see-more">
        <button class="filter-label reqDetails" type="button" data-toggle="collapse" data-target="#reqDetails" aria-expanded="false">
            <span>Request Details</span>
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="22.354px" height="22.354px" viewBox="0 0 22.354 22.354"
                 xml:space="preserve">
                      <g id="horizontal">
                        <rect y="8.61" width="21.354" height="3.633"></rect>
                      </g>
                <g id="vertical">
                        <rect x="8.61" width="3.633" height="21.354"></rect>
                      </g>
                    </svg>
        </button>
        <!-- edit request data -->
        <a class="edit-data" href="#">
            <svg version="1.1" xmlns="http://www.w3.org/2000/svg"  width="528.899px" height="528.899px" viewBox="0 0 528.899 528.899">
                <g>
                    <path d="M328.883,89.125l107.59,107.589l-272.34,272.34L56.604,361.465L328.883,89.125z M518.113,63.177l-47.981-47.981
                          c-18.543-18.543-48.653-18.543-67.259,0l-45.961,45.961l107.59,107.59l53.611-53.611
                          C532.495,100.753,532.495,77.559,518.113,63.177z M0.3,512.69c-1.958,8.812,5.998,16.708,14.811,14.565l119.891-29.069
                          L27.473,390.597L0.3,512.69z" />
                </g>
            </svg>
        </a>
        <!-- /edit request data -->
        <div class="collapse" id="reqDetails">
            <div class="filter-label-content">
                <div class="req-data">
                    <h5>Your Department</h5>
                    <div class="box" data-type="text">Church goers</div>
                </div>
                <div class="req-data">
                    <h5>Brief Description of Request</h5>
                    <div class="box" data-type="textarea">Lorem ipsum Sed ut perspiciatis unde omnis iste natus error sit voluptatem accusantium doloremque laudantium, totam rem aperiam eaque ipsa quae ab illo inventore veritatis et quasi architecto beatae.</div>
                </div>
                <div class="req-data">
                    <h5>Target Audience</h5>
                    <div class="box" data-type="textarea">Church goers</div>
                </div>
                <div class="req-data">
                    <h5>Who needs to be in the video?</h5>
                    <div class="box" data-type="textarea">N/A</div>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        components: {

        },
        name: "todo-form",
        mounted() {
        },
        computed: {
        }
    };
    </script>

<template>
    <div class="tab-pane fade show active" id="nav-list" role="tabpanel" aria-labelledby="list-tab" >
    <div class="requests-wrapper" >
    <div class="requests-container d-flex"  v-for="(item, index) in items.data" data-target="#desc1" >

        <div class="d-flex align-items-center requestor" @click="showUserData(item)">
            <img :src="(item.applicant_profile ? item.applicant_profile : '/img/profile-placeholder.png')" alt=""
                    class="rounded-circle">
            <div class="request-info ml-3">
                <h4>{{(item.name != '' ? item.name :item.form_title)}}

                </h4>
                <div class="name">{{item.user.name}}</div>
            </div>
        </div>
        <div class="d-flex more-details">
            <div class="request-status">
                <select :value="item.status" :disabled="isRequester" v-model="item.status" class="selectpicker" @change="updateApproval($event,item.id)"
                        :id="'status_' + item.id">
                    <option v-for="(key, statusOption) in statusData" :value="statusOption"
                            >{{key}}
                    </option>
                </select>
            </div>
            <div class="request-assign">
                <select :value="item.assigned_to" :disabled="isRequester" @change="updateManager($event,item.id)"
                        :id="'assignedTo_' + item.id" title="Select Approval Manager" class="selectpicker" data-live-search="true">
                    <option v-for="managerOption in approvalManager" :value="managerOption.id">{{managerOption.name}}
                    </option>
                </select>
            </div>
        </div>

        <div class="d-flex request-action">
            <button type="button" class="gray-link add-message bg-transparent" @click="showUserData(item)" data-target="#desc1">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="77.5" height="73.1"
                        viewBox="0 0 77.5 73.1">
                    <path class="st0" d="M72.6,59.9H4.9C2.2,59.9,0,57.7,0,55V4.9C0,2.2,2.2,0,4.9,0h67.7c2.7,0,4.9,2.2,4.9,4.9V55
                    C77.5,57.7,75.3,59.9,72.6,59.9z"/>
                    <polygon class="st0" points="12.7,72.1 12.7,58.9 30.1,58.9 "/>
                    <rect x="21.4" y="17.4" fill="#ffffff" width="34.5" height="8.1"/>
                    <rect x="21.4" y="31.8" fill="#ffffff" width="25.9" height="8.1"/>
                </svg>
            </button>

            <button type="button" class="gray-link archive-req bg-transparent" @click="archiveRequest(item.church_id,item.id)">
                <svg xmlns="http://www.w3.org/2000/svg" width="18" height="18" viewBox="0 0 18 18">
                    <path fill-rule="evenodd"
                            d="M17.5 2.2L16.1.5C15.9.2 15.5 0 15 0H3c-.5 0-.9.2-1.2.5L.5 2.2C.2 2.6 0 3 0 3.5V16c0 1.1.9 2 2 2h14c1.1 0 2-.9 2-2V3.5c0-.5-.2-.9-.5-1.3zM9 14.5L3.5 9H7V7h4v2h3.5L9 14.5zM2.1 2l.8-1h12l.9 1H2.1z"/>
                </svg>
            </button>

            <button type="button" class="gray-link more-desc bg-transparent" @click="showUserData(item)">
                <svg xmlns="http://www.w3.org/2000/svg" width="320" height="512" viewBox="0 0 320 512">
                    <path
                        d="M285.476 272.971L91.132 467.314c-9.373 9.373-24.569 9.373-33.941 0l-22.667-22.667c-9.357-9.357-9.375-24.522-.04-33.901L188.505 256 34.484 101.255c-9.335-9.379-9.317-24.544.04-33.901l22.667-22.667c9.373-9.373 24.569-9.373 33.941 0L285.475 239.03c9.373 9.372 9.373 24.568.001 33.941z">
                    </path>
                </svg>
            </button>
        </div>

    </div>
        <div class="card" v-if="dataFlag">
            <div class="card-header">No Requests</div>

            <div class="card-body">
                Currently you do not have any request.
            </div>
        </div>

        <request-detail ref="modalStatus" v-model="userData" :user-data="userData" :approvalManager="approvalManager" :isRequester="isRequester"
                       @focus="disableClick" :statusData="statusData" @close="hideUserData" v-if="showUserDataModal"></request-detail>
        <aside class="request-filters" id="requestFilters">
            <!-- search -->
            <form id="filter" v-on:submit.prevent="searchApplication">
            <div class="wrapper navbar-search">

                    <div class="input-group">
                        <i class="fas fa-search"></i>
                        <input type="text" class="form-control small" name="search" placeholder="Search"
                               aria-label="Search" >
                    </div>

                </div>
                <!-- requests type -->
                <div class="wrapper">
                    <button class="filter-label bg-transparent" type="button" data-toggle="collapse" data-target="#reqType"
                            aria-expanded="true">
                        <span>Requests</span>
                        <i class="fa fa-angle-down"></i>
                    </button>
                    <div class="collapse show" id="reqType">
                        <div class="filter-label-content">

                            <div class="form-group">
                                <input type="checkbox" v-on:click="searchApplication" value="created_by_me"
                                       name="application[]" id="myRequests">
                                <label for="myRequests">My Requests</label>
                            </div>
                            <div class="form-group">
                                <input type="checkbox" v-on:click="searchApplication" value="archived" name="application[]"
                                       id="archived">
                                <label for="archived">Archived</label>
                            </div>
                        </div>
                    </div>
                </div>
                <!-- status type -->
                <div class="wrapper">
                    <button class="filter-label bg-transparent" type="button" data-toggle="collapse" data-target="#statusType"
                            aria-expanded="true">
                        <span>Status</span>
                        <i class="fa fa-angle-down"></i>
                    </button>
                    <div class="collapse show" id="statusType">
                        <div class="filter-label-content">
                            <div class="form-group" v-for="(statusOption,key) in statusData">
                                <input type="checkbox" v-on:click="searchApplication" name="status[]"
                                       :id="'pendingAppr' + key" :value="key">
                                <label :for="'pendingAppr' + key">{{statusOption}}</label>
                            </div>
                        </div>
                    </div>
                </div>

                <!-- see more filters -->
                <div class="wrapper see-more">
                    <button class="filter-label bg-transparent" type="button" data-toggle="collapse" data-target="#seeMoreFilters"
                            aria-expanded="false">
                        <i class="fas fa-caret-right"></i>
                        <span>See More Filters</span>
                    </button>
                    <div class="collapse" id="seeMoreFilters">
                        <div class="filter-label-content">
                            <!-- Form name type -->
                            <div class="wrapper">
                                <button class="filter-label bg-transparent" type="button" data-toggle="collapse"
                                        data-target="#formNameType" aria-expanded="true">
                                    <span>Form Name</span>
                                    <i class="fa fa-angle-down"></i>
                                </button>
                                <div class="collapse show" id="formNameType">
                                    <div class="filter-label-content">
                                        <div class="form-group" v-for="(form,key) in allForms">
                                            <input v-on:click="searchApplication" type="checkbox" name="form[]" :value="form.id" :id="'desReq' + key">
                                            <label :for="'desReq' + key">{{ form.church_form.name}}</label>
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <!-- sort requests by -->
                            <div class="wrapper">
                                <button class="filter-label bg-transparent" type="button" data-toggle="collapse"
                                        data-target="#dateType" aria-expanded="true">
                                    <span>Requests</span>
                                    <i class="fa fa-angle-down"></i>
                                </button>
                                <div class="collapse show" id="dateType">
                                    <div class="filter-label-content">

                                        <div class="form-group">
                                            <input v-on:click="searchApplication" type="checkbox" name="due_date" id="dueDate">
                                            <label for="dueDate">Due Date</label>
                                        </div>

                                    </div>
                                </div>
                            </div>
                            <!-- number of requests to display -->
                            <div class="wrapper">
                                <button class="filter-label bg-transparent" type="button" data-toggle="collapse"
                                        data-target="#reqdisplay" aria-expanded="true">
                                    <span>Choose No. of Request to display on Home Page</span>
                                    <i class="fa fa-angle-down"></i>
                                </button>
                                <div class="collapse show" id="reqdisplay">
                                    <div class="filter-label-content">
                                        <div class="form-group select-form-group">
                                            <select class="form-control" name="per_page" id="sortRequestType"
                                                    @change="searchApplication()">
                                                <option value="10">10</option>
                                                <option value="20">20</option>
                                                <option value="30">30</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </form>
        </aside>

    </div>
    <div class="admin-theme">
        <nav aria-label="Page navigation user-list" class="right-pagination">
            <pagination :data="items" @pagination-change-page="getResults">
                <span slot="prev-nav">Previous</span>
                <span slot="next-nav">Next</span>
            </pagination>
        </nav>
    </div>
    </div>
</template>

<script>

    export default {
        name: 'RequestListing',

        props: [''],
        data() {
            return {
                pageOfItems: [],
                userData: null,
                items: {
                    name: "",
                },
                statusData: {},
                showUserDataModal: false,
                approvalManager: {},
                allForms: {},
                dataFlag: false,
                isRequester: false,
            };
        },
        mounted() {
            // Fetch initial results
            this.getResults();
            this.statusData = this.$attrs.statusdata;
            this.approvalManager = this.$attrs.approvalmanager;
            this.allForms = this.$attrs.allforms;
            $("#filterBtn").on("click", function () {
                $("#requestFilters").toggleClass("show");
            });
            $("#ltb").on("click",function() {
                $("#filterBtn").show();
            });
            $("#ctb").on("click",function() {
                $("#filterBtn").hide();
            });
        },
        updated() {
            $(".selectpicker").selectpicker('refresh');
        },
        methods: {
            disableClick: function(){

            },
            archiveRequest: function(church_id,app_id){
                axios.post('/church/'+church_id+'/request/'+ app_id+'/archive')
                    .then(response => {
                        this.getResults();
                    });
            },
            updateManager(event, id) {
                let vm = this;
                debugger
                axios.post('/account/' + id + '/update_status', {
                    assigned_to: parseInt(event.target.value),
                }).then(function (response) {
                    $('#assignedTo_' + id).selectpicker('refresh');
                    vm.$nextTick(()=>{
                        vm.$forceUpdate();
                    })
                }).catch(err => {
                    alert(err.message);
                });
            },
            updateApproval(event, id) {
                debugger
                let vm = this.userData;
                axios.post('/account/' + id + '/update_status', {
                    status: parseInt(event.target.value),
                }).then(function (response) {
                    $('#status_' + id).selectpicker('refresh');
                    this.$nextTick(()=>{
                        this.$forceUpdate();
                    })
                }).catch(err => {
                });
            },
            reloadComponent(){
                let vmobj = this;
                axios({
                    method:'get',
                    url:'/account/events'
                }).then(function(response){
                    vmobj.eventList = response.data;
                    console.log(vmobj.eventList);
                    //vmobj.message="Notification Settings Update Successfully!";
                }).catch(function(error){
                    console.log(error);
                })
            },
            // Our method to GET results from a Laravel endpoint
            getResults(page = 1) {
                let formdata = $("#filter").serialize();
                this.dataFlag=false;
                $('.ajax-overlay').removeClass('d-none');
                axios.get('/account/applications/pages?page=' + page + '&' + formdata)
                    .then(response => {
                        $('.ajax-overlay').addClass('d-none');
                        this.items = response.data.application;
                        this.isRequester = response.data.isRequester;
                        if(this.items.data.length === 0){
                            this.dataFlag=true;
                        }
                        this.$nextTick(()=>{
                            this.$forceUpdate();
                        })
                    });
            },
            searchApplication() {
                this.getResults();
            },
            onChangePage(pageOfItems) {
                // update page of items
                this.pageOfItems = pageOfItems;
            },
            showUserData(item) {
                if(this.showUserDataModal == false) {
                    this.showUserDataModal = true;
                    this.userData = item;
                }
                else {
                    this.hideUserData();
                }
            },
            hideUserData() {
                this.showUserDataModal = false;
                this.userData = null;
            }

        }
    };
</script>



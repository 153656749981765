<template>
    <div class="main-start">
        <div class="task-search-wrap">
            <h1 class="h2 mb-5">Tasks</h1>
            <div class="navbar-search">
                <div class="input-group">
                    <i class="fas fa-search"></i>
                    <input @input="debounceSearch" type="text" v-model="search" class="form-control bg-light small"
                           placeholder="Search" aria-label="Search" aria-describedby="basic-addon2"/>
                    <div class="btn-reset" v-if="search" @click="resetSearch()">
                        <i class="fas fa-times-circle"></i>
                    </div>
                </div>
            </div>
            <button class="btn btn-blue" v-if="canEdit" @click="showNewTodo">Add new Task
            </button>
        </div>
        <div class="requests-wrapper mt-4">
            <div class="text-center" v-if="tasks.length < 1">
                <h4>No Result Found</h4>
            </div>
            <div @click="enableTaskDetailModal(task)" class="requests-container task-cont"
                 :class="[showTaskDetailModal && currentContainerId === task.id? 'selected': '', task.status === 1 ? 'completed-task' : '']"
                 v-for="task in tasks" :key="task.id">
                <div class="requestor">
                    <h4>{{ task.title }}</h4>
                </div>
                <div class="td-wrap">
                    <div class="form-group due-on" @click="stopPropogation">
                        <label>Due on</label>
                        <vuejs-datepicker :disabledDates="disabledDates" placeholder="Set Due Date"
                                          v-model="task.actual_end_date" @input="updateTodoDate(task)"
                                          :disabled="task.status === 1 || !canEdit"></vuejs-datepicker>
                    </div>
                    <div class="form-group task-assign" @click="stopPropogation">
                        <label>Assigned To</label>
                        <select :disabled="task.status === 1 || !canEdit" v-model="task.assinged_user_id"
                                class="form-control" id="" @change="updateTodoAssignee(task.assinged_user_id, task)">
                            <option :value="nullValue" disabled>Select User</option>
                            <option v-for="user in users" :value="user.id">{{user.name}}
                            </option>
                        </select>
                    </div>
                </div>
                <div class="d-flex request-action" @click="enableTaskDetailModal(task)">
                    <button type="button" class="gray-link more-desc">
                        <i class="fas fa-angle-right"></i>
                    </button>
                </div>
            </div>
        </div>
        <div class="alert alert-success position-fixed fixed-bottom" v-if="showSuccessMessage">{{ successMessage }}
        </div>
        <nav aria-label="Page navigation user-list" class="right-pagination" v-if="totalPages > 1">
            <ul class="pagination">
                <li @click="getPaginatedData(activePage - 1, search)" class="page-item"
                    :class="activePage <= 1 ? 'disabled' : ''" v-if="activePage > 1">
                    <a class="page-link " href="#">Previous</a>
                </li>
                <li @click="getPaginatedData(page, search)" class="page-item" v-for="page in totalPages"
                    :class="page === activePage ? 'active' : ''">
                    <a class="page-link" href="#">{{ page }}</a>
                </li>
                <li @click="getPaginatedData(activePage + 1, search)" v-if="activePage < totalPages"
                    :class="activePage === totalPages ? 'disabled' : ''" class="page-item">
                    <a class="page-link" href="#">Next</a>
                </li>
            </ul>
        </nav>
        <task-detail @destroy-todo="destroyTask" @update-task-title="updateTodoTitle" @update-task-note="updateTodoNote"
                     @update-due-date="updateTodoDate" @update-task-assignee="updateTodoAssignee"
                     @update-task-status="updateTodoStatus" :churchUsers="users" :taskDetail="taskDetail"
                     :applicationDetail="requestDetail" :canEdit="canEdit"
                     @close-task-detail-modal="disableTaskDetailModal" v-if="showTaskDetailModal"></task-detail>

        <create-task @refresh-tasks="refreshTasks" v-if="showCreateTask" @close-create-task="closeNewTodo"
                     :users="users" :requests="allRequests"></create-task>
    </div>
</template>

<script>
    export default {
        name: "TasksListing",
        data() {
            return {
                showTaskDetailModal: false,
                tasks: [],
                users: [],
                requestDetail: "",
                taskDetail: "",
                totalPages: "",
                activePage: 1,
                showSuccessMessage: false,
                successMessage: "",
                currentContainerId: "",
                showCreateTask: false,
                allRequests: [],
                debounce: null,
                search: "",
                nullValue: null,
                disabledDates: {
                    to: {}
                },
                canEdit: false
            };
        },
        created() {
            var dateObj = new Date();
            dateObj.setDate(dateObj.getDate() - 1);
            this.disabledDates.to = dateObj;
        },
        mounted() {
            this.tasks = this.$attrs.tasks;
            this.users = this.$attrs.churchusers;
            this.totalPages = this.$attrs.totalpages;
            this.allRequests = this.$attrs.requests;
            this.canEdit = this.$attrs.canaddtask;
        },
        methods: {
            resetSearch() {
                this.search = "";
                this.getPaginatedData(1, this.search);
            },
            debounceSearch(event) {
                clearTimeout(this.debounce);
                this.debounce = setTimeout(() => {
                    this.search = event.target.value;
                    this.getPaginatedData(1, event.target.value);
                }, 1500);
            },
            showNewTodo() {
                this.showCreateTask = true;
            },

            stopPropogation(e) {
                e.stopPropagation();
            },
            resetSearch() {
                this.search = '';
                this.getPaginatedData(1, this.search)
            },
            debounceSearch(event) {
                clearTimeout(this.debounce)
                this.debounce = setTimeout(() => {
                    this.search = event.target.value;
                    this.getPaginatedData(1, event.target.value)
                }, 1500)
            },
            showNewTodo() {
                this.showCreateTask = true;
            },
            closeNewTodo() {
                this.showCreateTask = false;
            },
            async enableTaskDetailModal(task) {
                this.requestDetail = [];
                this.taskDetail = [];
                const response = await axios.get(task.application_id + '/fetchDetail');
                this.requestDetail = response.data;
                this.taskDetail = task;
                this.currentContainerId = task.id;
                this.showTaskDetailModal = true;

            },
            isgreater: function (actualDate) {
                let newDate = new Date();
                let actual = new Date(actualDate);
                return actual < newDate;
            },
            disableTaskDetailModal() {
                this.showTaskDetailModal = false;
            },
            format_date(value) {
                if (value) {
                    return moment(String(value)).format('DD MMM YYYY');
                }
            },
            getPaginatedData(page, name) {
                $('.ajax-overlay').removeClass('d-none');
                axios.get('/account/tasks/page?page=' + page + '&name=' + name)
                    .then(response => {
                        this.tasks = response.data.tasks.data;
                        this.totalPages = response.data.tasks.last_page;
                        $('.ajax-overlay').addClass('d-none');
                        this.activePage = page;
                        if (this.successMessage !== '') {
                            var self = this;
                            setTimeout(function () {
                                self.showSuccessMessage = false;
                                self.successMessage = '';
                            }, 3000);
                        }
                    });
            },
            updateTodoAssignee(asigneeId, todo) {
                $('.ajax-overlay').removeClass('d-none');
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax({
                    type: 'POST',
                    url: '/account/task/update/assignee',
                    data: {asigneeId: asigneeId, todoId: todo.id},
                    success: (data) => {
                        this.successMessage = data.success;
                        $('.ajax-overlay').addClass('d-none');
                        this.showSuccessMessage = true;
                        var self = this;
                        setTimeout(function () {
                            self.successMessage = '';
                            self.showSuccessMessage = false;
                        }, 3000);
                    }
                });
            },
            getActualDate(date) {
                var date = new Date(date);
                const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun",
                    "Jul", "Aug", "Sepr", "Oct", "Nov", "Dec"
                ];
                const day = date.getDate();
                const month = monthNames[date.getMonth()];
                const year = date.getFullYear();
                return (`${day} ${month} ${year}`);
            },
            updateTodoDate(task) {
                const date = task.actual_end_date.getDate();
                const month = task.actual_end_date.getMonth() + 1;
                const year = task.actual_end_date.getFullYear();
                const fullDate = `${year}-${month}-${date}`;
                $('.ajax-overlay').removeClass('d-none');
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax({
                    type: 'POST',
                    url: '/account/task/update/duedate',
                    data: {asigneeId: task.assinged_user_id, dueDate: fullDate, todoId: task.id},
                    success: (data) => {
                        $('.ajax-overlay').addClass('d-none');
                        this.successMessage = data.success;
                        this.showSuccessMessage = true;
                        var self = this;
                        setTimeout(function () {
                            self.showSuccessMessage = false;
                        }, 3000);
                    }
                });
            },
            updateTodoNote(task) {
                $('.ajax-overlay').removeClass('d-none');
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax({
                    type: 'POST',
                    url: '/account/task/update/note',
                    data: {note: task.note, todoId: task.id},
                    success: (data) => {
                        $('.ajax-overlay').addClass('d-none');
                        this.successMessage = data.success;
                        this.showSuccessMessage = true;
                        var self = this;
                        setTimeout(function () {
                            self.showSuccessMessage = false;
                        }, 3000);
                    }
                });
            },
            updateTodoTitle(task) {
                $('.ajax-overlay').removeClass('d-none');
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax({
                    type: 'POST',
                    url: '/account/task/update/title',
                    data: {title: task.title, todoId: task.id},
                    success: (data) => {
                        $('.ajax-overlay').addClass('d-none');
                        this.successMessage = data.success;
                        this.showSuccessMessage = true;
                        var self = this;
                        setTimeout(function () {
                            self.showSuccessMessage = false;
                            self.successMessage = "";
                        }, 3000);
                    }
                });
            },
            updateTodoStatus(task) {
                $(".ajax-overlay").removeClass("d-none");
                $.ajaxSetup({
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr("content")
                    }
                });
                $.ajax({
                    type: "POST",
                    url: "/account/task/update/status",
                    data: {
                        asigneeId: task.assinged_user_id,
                        status: task.status,
                        todoId: task.id
                    },
                    success: data => {
                        $(".ajax-overlay").addClass("d-none");
                        this.refreshTasks(this.activePage, data.success);
                    }
                });
            },
            destroyTask(task){
                $('.ajax-overlay').removeClass('d-none');
                this.showTaskDetailModal = false;
                $.ajaxSetup({
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    }
                });
                $.ajax({
                    type: 'DELETE',
                    url: '/account/task/delete',
                    data: {todo_id: task.id},
                    success: (data) => {
                        this.refreshTasks(this.activePage, data.success);
                    }
                });
            },
            refreshTasks(page, successMessage) {
                this.showSuccessMessage = true;
                this.successMessage = successMessage;
                this.showCreateTask = false;
                this.showTaskDetailModal = false;
                this.getPaginatedData(page, this.search);
            },
        }
    }
    ;
</script>

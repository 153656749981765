<template>
    <div>
        <h6 class="h6">Notifications</h6>
        <div class="setting-cont shadow">
            <!-- @foreach ($allNotifications as $notification => $id ) -->
            <div class="table-responsive">
                <table class="table table-striped table-bordered" id="invitedUserDataTable" width="100%"
                        cellspacing="0">
                    <thead>
                        <tr>
                            <th>Notification</th>
                            <th>Email</th>
                            <th>In App</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="(notification, key) in allNotifications">
                            <td>{{ key}}</td>
                            <td><input type="checkbox" v-bind:value="notificationsMerge[0][key]" :checked="true" v-model="notifications"></td>
                            <td><input type="checkbox" v-bind:value="notificationsMerge[1][key]" :checked="true" v-model="appNotifications"></td>
                        </tr>

                    </tbody>
                </table>
            </div>
            <!-- @endforeach -->
            <div class="formAction">
                <button class="btn btn-submit" @click="saveNotifications()" name="update_profile">Save</button>
            </div>
            <div v-if="message" class="text-success">{{ message }}</div>
        </div>
    </div>
</template>
<script>
export default {
    name:'notifications-settings-component',
    props:{
        allNotifications:{
            type: Object,
            default() {
                return [];
            }
        },
        inAppNotifications:{
            type: Object,
            default() {
                return [];
            }
        },
        checkedNotifications:{
            type: Array,
            default() {
                return [];
            }
        },
        checkedAppNotifications:{
            type: Array,
            default() {
                return [];
            }
        }
    },
    data(){
        return {
            notification:'',
            notifications:this.checkedNotifications,
            appNotifications:this.checkedAppNotifications,
            notificationsMerge:[this.allNotifications,this.inAppNotifications],
            message:''
        }
    },
    methods:{
        saveNotifications()
        {
            let vmobj=this;
            axios({
                method:'post',
                url:'/account/notification',
                data:{
                    checkedEmailNotifications:this.notifications,
                    checkedAppNotifications:this.appNotifications
                }
            }).then(function(response){
                vmobj.message="Notification Settings Update Successfully!";
            }).catch(function(error){
                vmobj.message=error.response.data.errors.video[0];
            })
        }
    }
}
</script>
<style scoped>

</style>

<template>
    <div>
        <ul class="calendar-indicators">
            <li>Submitted</li>
            <li>Assigned</li>
            <li>Others</li>
        </ul>

        <FullCalendar
                defaultView="dayGridMonth"
                :header="headerMenu"
                :plugins="calendarPlugins"
                :weekends="true"
                :events="eventList"
                @eventClick="showUserData($event)"
                ></FullCalendar>
        <request-detail :user-data="userData" :statusData="status" :isRequester="isRequester" :approvalManager="approvalManagers" @close="hideUserData" v-if="showUserDataModal"></request-detail>
    </div>
</template>
<script>
import FullCalendar from '@fullcalendar/vue';
import dayGridPlugin from '@fullcalendar/daygrid';
import timeGridPlugin from '@fullcalendar/timegrid';
import listPlugin from '@fullcalendar/list';

export default {
    name:'calendar-component',
    props:{
        allEvents: {
            type:Array,
            default() {
                return []
            }
        },
        statusData: {},
        approvalManager: {}
    },
  data() {
        return{
            calendarPlugins: [ dayGridPlugin, timeGridPlugin, listPlugin],
            headerMenu: {
                    left: 'prev,next today',
                    center: 'title',
                    right: 'dayGridMonth,timeGridWeek,timeGridDay'
                },
            eventList: this.allEvents,
            requestData:{},
            userData: null,
            showUserDataModal: false,
            status: {},
            approvalManagers: {},
            message:'',
            isRequester:false,
            userRole:null
        }
    },
    created() {
        $(".selectpicker").selectpicker('refresh');
        this.$nextTick(()=>{
            this.$forceUpdate();
        });
        this.userRole = this.allEvents.pop();
    },
    mounted(){
        this.status = this.statusData,
        this.approvalManagers = this.approvalManager,
        this.isRequester = this.userRole.isRequester
    },
    components: {
        FullCalendar // make the <FullCalendar> tag available
    },
    methods: {
        showUserData(info) {
            info.jsEvent.preventDefault();
            this.userData = info.event.extendedProps.data;
            this.showUserDataModal = true;
        },
        hideUserData() {
            this.showUserDataModal = false;
            this.userData = null;
        },
        reloadComponent(){
            let vmobj = this;
             axios({
                method:'get',
                url:'/account/events'
             }).then(function(response){
                response.data.pop();
                vmobj.eventList = response.data;
            }).catch(function(error){
                //console.log(error);
            })

        },
        updateManager(event, id) {
                let vm = this;
                axios.post('/account/' + id + '/update_status', {
                    assigned_to: parseInt(event.target.value),
                }).then(function (response) {
                    $('#assignedTo_' + id).selectpicker('refresh');
                    vm.$nextTick(()=>{
                        vm.$forceUpdate();
                    })
                }).catch(err => {
                    alert(err.message);
                });
            },
            updateApproval(event, id) {

                let vm = this.userData;
                axios.post('/account/' + id + '/update_status', {
                    status: parseInt(event.target.value),
                }).then(function (response) {
                    $('#status_' + id).selectpicker('refresh');
                    this.$nextTick(()=>{
                        this.$forceUpdate();
                    })
                }).catch(err => {
                });
            },
    }
}
</script>


$(document).ready(function (e) {

  var todoCommentVisibleLimit = 35;

  $(document).on('shown.bs.collapse','#todo-section .todo-collapse',function(){
    let collpaseDataid = $(this).data('id'),
        explodecollpaseDataid = collpaseDataid.split('_');
    $("#note_desc_"+explodecollpaseDataid[2]).text('');
  });

  $(document).on('change', '#todo-section .todo_datepicker', function(){
   let hiddenDatePickerId = $(this).attr('id'),
      pickedDate =   $("#"+hiddenDatePickerId+"_hidden").val();
      var CurrentDate = new Date();
      GivenDate = new Date(pickedDate);

      if(CurrentDate.toDateString() == GivenDate.toDateString()){
        if ($(this).hasClass("passed-due-date")) {
              $(this).removeClass('passed-due-date');
          }
      }
      else if(GivenDate > CurrentDate){
        if ($(this).hasClass("passed-due-date")) {
              $(this).removeClass('passed-due-date');
          }
      }else{
        if (!$(this).hasClass("passed-due-date")) {
              $(this).addClass('passed-due-date');
          }
      }
  });


  $(document).on('hidden.bs.collapse','#todo-section .todo-collapse',function(){
    let collpaseDataid =  $(this).data('id'),
        explodecollpaseDataid = collpaseDataid.split('_');
    var textareaValue =   $(this).find('textarea.todo_textarea').val();
    if(textareaValue.length > todoCommentVisibleLimit){
                let readMoreSpan  = '<span class = "btn btn-link p-0" data-toggle="collapse"  data-target="#todoCollapse_'+explodecollpaseDataid[2]+'">... Read More </span>';
              $('#note_desc_'+explodecollpaseDataid[2]).html(textareaValue.substring(0,todoCommentVisibleLimit) + readMoreSpan);
            }else{
              $('#note_desc_'+explodecollpaseDataid[2]).html(textareaValue.substring(0,todoCommentVisibleLimit));
            }
  });


    $(document).on('focus',"#todo-section .todo_datepicker", function(){
          let duedateId = $(this).attr('id');
          $("#"+duedateId).datepicker({
            dateFormat: 'd M',onSelect: function (dateText, inst)
           {
            $(this).off( "focus" );
             let selectedDate =   $(this).datepicker('getDate');
              $("#"+duedateId+'_hidden').val($.datepicker.formatDate("yy-mm-dd", selectedDate));
              $("#"+duedateId).val(dateText);
              $("#"+duedateId).change();

              $(this).parent().addClass('selected');
           }
          }).datepicker("show");
       });

        $(document).on('click','#request-details .modal-content', function(e) {
            if  (!(jQuery(event.target).closest(".to-do-row").length)) {
             $('.to-do-row').removeClass('focus-active');
             $('.to-do-row .collapse').collapse('hide');
            }
        });

        $(document).on('click', '#todo-section .to-do-row', function(e){
            $('.to-do-row').removeClass('focus-active');
            $(this).addClass('focus-active');
            var clickcol= $(this).find('.collapse');
            $('.to-do-row .collapse').not(clickcol).collapse('hide');

        });

        if (!$(e.target).is('.panel-body')) {
          $('.collapse').collapse('hide');
        }


   var count =  makeRandomString(5);
    var submitFormStatus = true;

    $(document).on('click', '#add_todo_btn', function (e) {
        let applicationId =  $(this).data('application-id');
        $(this).prop("disabled", true);
         $.ajax({
            url : route('todo.create', [window.appConfig.subDomain,applicationId ]).url(),
            type : 'GET',
            dataType : 'HTML',
            data : {count : due_date}
         }).done(function(respnse){
            $("#addtodo_section").prepend(respnse);
            $('#todo_assign_user_'+count).select2({
              'data':selectUserData,
              templateSelection: userIconOnAddtodo,
              });
              var $eventSelect = $("form#todo_form_"+count);
              $eventSelect.on("select2:open", function (e) { submitFormStatus = false; });
              $eventSelect.on("select2:close", function (e) { submitFormStatus = true; $('.todo_input:first').blur(); });
             due_date =  makeRandomString(5)
            setTimeout( function(){
                  $("#add_todo_btn").removeAttr("disabled");
              }  , 500 );

         });
    });


    $(document).on('blur', '#todo-section .todo_input', function(e){
        let formId  = $(this).closest('form').attr('id'),
            $formId = "form#"+formId;
        let applicationId = $($formId).data('application-id');
            setTimeout(function() {
                if (!($($formId +"input,select,textarea,.todo_datepicker").is(":focus"))) {
                    if ($($formId).hasClass("add_todo_form") && (submitFormStatus == true)) {
                        $($formId).removeClass("add_todo_form");
                        submitAddTodoForm($formId,applicationId);
                    }
                }
            }, 1500);
            return false;
    });


    var completeStatus = 0;
    $(document).on('click', "#todo-section button.btn-complete", function(e){
        e.preventDefault();
        if($(this).hasClass('add_todo_checkbox')){
            completeStatus = (completeStatus === 0) ? 1 : 0;
        }
        $(this).parent('form').toggleClass('todo-completed');
    });


    function submitAddTodoForm(formId,applicationId)
        {
          console.log('inside submit form');
            $.ajax({
                url: route('todo.store', [window.appConfig.subDomain,applicationId ]).url(),
                type: 'POST',
                headers: {'X-CSRF-TOKEN': window.appConfig.csrfToken},
                data:  ($(formId).serialize() + "&complete=" + completeStatus),
                dataType:'HTML',
            }).done(function (response) {
                   let responseObj = JSON.parse(response);
                   $(formId).attr('data-todoid',responseObj.todoId);

                   $(formId+" input, textarea,select").removeClass('todo_input').addClass('edit_todo_input');
                   $(formId+" button.add_todo_checkbox").attr('data-value',responseObj.todoId).removeClass('add_todo_checkbox').addClass('todo_status');
                   $(formId).removeClass('add_todo_form').addClass('edit_todo_form');
                 setTimeout(function() {
                }, 3000);
            }).fail(function (error) {
                console.log(error);
            });
        }


        $(document).on('change', '#todo-section select.edit_todo_input', function(e){
            $("#"+$(this).closest('form').attr('id')).find("select.edit_todo_input").blur()
        });

      //** Track if any change happen in edit input or textarea

       $(document).on('change', '#todo-section .edit_todo_input', function(e){
             let applicationId = $(this).closest('form').data('application-id'),
                 $formId = $(this).closest('form').attr('id');
                 setTimeout(function() {
                        if (!($("#"+$formId +"input,select,textarea,.edit_todo_input").is(":focus")) && $("#"+$formId).hasClass("edit_todo_form")) {
                            updateTodoForm("#"+$formId, applicationId);
                        }
                    },
            1000);
            return false;
        });

//** Udate Todo Form
        function updateTodoForm(formId, applicationId)
        {
            let todoId = $(formId).data('todoid');
            $.ajax({
                url: route('todo.update', [window.appConfig.subDomain,applicationId,todoId ]).url(),
                type: 'PUT',
                headers: {'X-CSRF-TOKEN': window.appConfig.csrfToken},
                data: ($(formId).serialize() + "&application_id=" + applicationId)
            }).done(function (response) {

            }).fail(function (error) {

            });

        }


      //** Udate Todo Status after click status button

        $(document).on('click', "#todo-section button.todo_status", function(e){
            let todoValue =  $(this).data('value');
                let applicationId = $(this).closest('form').data('application-id'),
                    formIdAddress = '#'+$(this).closest('form').attr('id');
            $.ajax({
                url: route('todo.complete', [window.appConfig.subDomain,applicationId ]).url(),
                type : 'POST',
                headers : {'X-CSRF-TOKEN': window.appConfig.csrfToken},
                data : {'todo_id' : todoValue, 'application_id':applicationId }

              }).done( function(response){

                }).fail( function (error){
                    console.log(error);
            });
            return false;
        });


   if (!$(e.target).is('.to-do-row')) {
    $('.to-do-row .collapse').collapse('hide');
  }



});


function userIconOnAddtodo (users) {
  if (!users.id) {
    return users.text;
  }
  var $users = $(
    '<span class="usr-info"><img/> <span class="usr-name"></span></span>'
  );
  if(users.profileType == 'img'){
        $users.find("span").text(users.name);
        $users.find("img").attr("src",users.userIcon);
      return $users;
    }

  $users.find("span").text(users.userIcon);
  $users.find("img").attr("src",'');
  return $users;
};

function makeRandomString(length) {
   var result           = '';
   var characters       = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
   var charactersLength = characters.length;
   for ( var i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
   }
   return result;
}

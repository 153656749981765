<template>
    <select class="selectpicker" @change="updateApproval($event)">
        <option v-for="statusOption in statusObject" :value="statusOption.id" :selected="statusOption.id ==status">{{statusOption.value}}</option>
    </select>
</template>
<script>
    export default {
        name: 'status-dropdown',
        props: ['status'],
        data() {
            return {
                messageList: [],
                statusObject:{
                    "0":{ id: "1", value: "Pending Approval"},
                    "1":{ id: "2", value: "On Hold"},
                    "2":{ id: "3", value: "Denied"},
                    "3":{ id: "4", value: "Active"},
                    "4":{ id: "5", value: "Complete"}  },
                application_id: null,
            }
        },
        mounted() {
            let vm = this;
            this.application_id = this.$attrs.application_id;
        },
        methods: {

        }
    };
</script>

<template>
    <div class="modal fade show mt-5" style="display: block;">
        <div class="modal-dialog">
            <div class="modal-content">
                <div class="modal-header">
                    <h5 class="modal-title">Delete Todo?</h5>
                    <button type="button" class="close" @click="close">
                        <span aria-hidden="true">&times;</span>
                    </button>
                </div>
                <div class="modal-body">
                    Are you sure you want to delete this todo?
                </div>
                <div class="modal-footer">
                    <button type="button" class="btn btn-secondary" @click="close">Cancel</button>
                    <button type="button" class="btn btn-primary" @click="deleteTodo">Yes</button>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
    export default {
        name: "DeleteTask",
        methods: {
            close() {
                this.$emit('cancel-delete');
            },
            deleteTodo() {
                this.$emit('delete-todo');
            },
        },
    }
</script>

'use strict';
jQuery(function ($) {
    var $requestMessageModal = $('.request_messages_modal');
    var $sendMessageBtn = $('#send_message_btn');

    var xhrInstance = null;
    $requestMessageModal.on('click', function (e) {
        topbar.show();
        var application_id = $(this).data('id');

        markMessageRead(application_id,$(this));

        console.log("this application id "+application_id);
        $("#message_application_id").val(application_id);

        //reset fields
        $("#messageText").val('');
        $("#message_attachment").val('');
        $('#messageFileCount').html('No file choosen');
        $('#message_group').removeClass('form-error');
        $('#message_error').html('');
        $('#message_file_group').removeClass('form-error');
        $('#message_file_error').html('');
        $("#message_send_success").html('');
        $sendMessageBtn.prop('disabled', false);

        show_application_messages(application_id);

        topbar.hide();
    });

    var xhrInstance = null;
    $sendMessageBtn  .on('click', function (e) {
        topbar.show();
        console.log("send message");
        $sendMessageBtn.prop('disabled', true);

        $('#message_group').removeClass('form-error');
        $('#message_error').html('');
        $('#message_file_group').removeClass('form-error');
        $('#message_file_error').html('');
        $("#message_send_success").html('');

        if (!$("#message_attachment").parsley().validate()) {
            $('#message_file_error').html('file is not validated');
            return false;
        }

        // var serializeStr = $sendMessageForm.serialize();
        var form_data = new FormData();
        var file_data = $("#message_attachment").prop('files');
        console.log(file_data);
        // form_data.append("file", file_data);
        var fileFlag=0;
        $.each(file_data, function (obj, v) {
            console.log(v);
            fileFlag++;
            var name = 'attachment_'+fileFlag;
            form_data.append(name, v);
            console.log('attachment : '+name);
        });

        form_data.append("application_id", $("#message_application_id").val());
        form_data.append("message", $("#messageText").val());

        console.log(form_data);
        $.ajax({
            url: route('application.send.message', [window.appConfig.subDomain]).url(),
            type: 'POST',
            dataType: 'json',
            cache: false,
            contentType: false,
            processData: false,
            data: form_data,
            headers: {
                'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
            },
            success: function (response) {
                topbar.hide();
                console.log(response);
                show_application_messages(response.data.application_id);
                $("#messageText").val('');
                $("#message_attachment").val('');
                $('#messageFileCount').html('No file choosen');
                $("#message_send_success").html('Message send successfully')
                $sendMessageBtn.prop('disabled', false);
            },
            error: function (jqXHR) {
                topbar.hide();
                var errResponse = JSON.parse(jqXHR.responseText);
                console.log(errResponse);
                $sendMessageBtn.prop('disabled', false);

                if (errResponse['errors']['message']) {
                    $('#message_group').addClass('form-error')
                    $('#message_error').html(errResponse['errors']['message'][0]);
                }

                if (errResponse['errors']['file']) {
                    $('#message_file_group').addClass('form-error')
                    $('#message_file_error').html(errResponse['errors']['file'][0]);
                }
            }
        });
    });

});

function show_application_messages(application_id) {

    $.get( route('application.messages', [window.appConfig.subDomain, application_id]).url(), '')
        .done(function (response) {
            console.log(response);

            $("#request_form_title").html(response.application.name);
            $("#requester_name").html(response.application.user.name);
            $("#requester_avatar").attr("src",response.applicant_avatar);

            var old_messages = '';
            if (response.data.length>0)
            {
                var current_user_id = response.current_user.id;
                console.log("current_user_id " + current_user_id);
                $.each(response.data, function (index, value) {


                    var attached_files = '';
                    var formattedDate = moment(value.created_at).format('MMM DD, YYYY');
                    var newFormattedDate = formattedDate;
                    // var formattedDate = new Date(value.created_at);
                    // var newFormattedDate = months[formattedDate.getMonth()] + ' ' + formattedDate.getDate() + ', ' + formattedDate.getFullYear();

                    if (value.message_details.length>0) {
                        $.each(value.message_details, function (fileindex, filevalue) {
                            var downloadUrl = route('application.file', [window.appConfig.subDomain]) + '/?name=' + filevalue.attachment + '&type=d';
                            attached_files += '<a href="'+downloadUrl+'">' + filevalue.detail + '</a>';
                        });
                    }

                    var attachments = '';
                    if(attached_files!='')
                    {
                        attachments = '<div class="attached-files">'+attached_files+'</div>';
                    }

                    var user_avatar = response.default_avatar;
                    if(value.user.user_detail)
                    {
                        if(value.user.user_detail.avatar!=null) {
                            user_avatar = response.avatar_dir + '' + value.user.user_detail.avatar;
                        }
                    }

                    if (current_user_id==value.user.id) {
                        old_messages += '<div class="sender messager">\n' +
                            '                            <div class="msg-img d-flex">\n' +
                            '                                <img src="' + user_avatar + '" alt="" class="img-circle">\n' +
                            '                                <div class="nameDate">\n' +
                            '                                    <div class="name">You</div>\n' +
                            '                                    <div class="date">' + newFormattedDate + '</div>\n' +
                            '                                </div>\n' +
                            '                            </div>\n' +
                            '                            <div class="msg-txt">' + value.message + '' + attachments + '</div>\n' +
                            '                        </div>';

                    }
                    else
                    {
                        old_messages += '<div class="receiver messager">\n' +
                            '                            <div class="msg-img d-flex">\n' +
                            '                                <img src="' + user_avatar + '" alt="" class="img-circle">\n' +
                            '                                <div class="nameDate">\n' +
                            '                                    <div class="name">' + value.user.name + '</div>\n' +
                            '                                    <div class="date">' + newFormattedDate + '</div>\n' +
                            '                                </div>\n' +
                            '                            </div>\n' +
                            '                            <div class="msg-txt">' + value.message + '' + attachments + '</div>\n' +
                            '                        </div>';
                    }
                });
            }

            $("#old_messages_container").html(old_messages);

            $("#message-user").modal('show');
        })
        .fail(function (jqXHR, textStatus) {
            var errResponse = JSON.parse(jqXHR.responseText);
            console.log(errResponse);
        })
        .always(function (jqXHR) {
            console.log("ajax called");
            //$ajaxLoaderModal.modal('hide');
        });

}

/**
 *
 * @param application
 */
function markMessageRead(application,$thisObject) {
    $.ajax({
            url: route('application.read_message', [window.appConfig.subDomain, application]).url(),
            type: 'POST',
            headers: {'X-CSRF-TOKEN': window.appConfig.csrfToken},
        }).done(function (response) {
            $thisObject.removeClass('new-message');
           console.log('updated message seen_at');
        }).fail(function (error) {
            console.log(error);
        });
}

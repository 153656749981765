jQuery(document).ready(function ($) {
    var oldPlan, selectedNewPlanVal, changeAction, continueStep2, selectedNewPlan, selectedPlanName, ActivePlan;
    $('#change_subscription').on('show.bs.modal', function (e) {
        var currentPlan = $("#new_plan_subscription input[type='radio']:checked");
        if (currentPlan.length > 0) {
            oldPlan = currentPlan.val();
        }
        $('#change_subscription #new_plan_features').hide();
        $('#change_subscription #new_plan_payment').hide();
        ActivePlan = $('#new_plan_subscription .modal-body').attr('active-plan');
        console.log(ActivePlan);

    });

    $('#new_plan_subscription input').on('click', function (e) {
        changeAction = $('.modal-footer #continue_plan_change').attr('data-href');
        continueStep2 = $('.modal-footer #continue_plan_change').attr('data-unique');
        selectedNewPlan = $("#new_plan_subscription input[type='radio']:checked");
        selectedPlanName = $("#new_plan_subscription .cs-plan-value input[type='radio']:checked").parent().text();
        selectedPlanName = $.trim(selectedPlanName)
        if (selectedNewPlan.length > 0) {
            var selectedNewPlanVal = selectedNewPlan.val();
        }
        if (selectedNewPlanVal === oldPlan) {
            $('.modal-footer #continue_plan_change').removeClass(continueStep2);

        } else {
            $('.modal-footer #continue_plan_change').addClass(continueStep2);
        }
    });


    $('body').on('click', '.change_plan_step_2', function () {
        $('#change_subscription #new_plan_subscription').hide();
        $('#change_subscription #new_plan_features').show();
        $('#change_subscription #new_plan_features .' + selectedPlanName).show();
    });

    $('#new_plan_features #showPlansList').on('click', function () {
        $('#change_subscription #new_plan_features').hide();
        $('#change_subscription #new_plan_features .feature-plan-list .plan-list').hide();
        $('#change_subscription #new_plan_subscription').show();
    });

    $('#continue_plan_payment').on('click', function () {
        $("input[name='new_selected_plan']").attr('value', selectedPlanName);
        $('#change_subscription #new_plan_subscription').hide();
        $('#change_subscription #new_plan_features').hide();
        $('#change_subscription .coupon-discount a').hide();
        $('#change_subscription .coupon-discount .' + selectedPlanName).show();
        $('#change_subscription #new_plan_payment form button span').hide();
        $('#change_subscription #new_plan_payment').show();
        $('#change_subscription #new_plan_payment .' + selectedPlanName).show();
        $('#change_subscription #new_plan_payment .' + selectedPlanName).addClass('selected-plan');
    });


    /**
     * Reset the Change Plan modal to the First Screen
     */
    $('body').on('click', '#cancel_subscription_button', function () {
        resetChangePlanModal();
    });


    $('#change_subscription .close').on('click', function () {
        resetChangePlanModal();
    });

    function resetChangePlanModal() {
        // location.reload(true);
        $('#change_subscription #new_plan_features .' + selectedPlanName).hide();
        $('#change_subscription .coupon-discount a').hide();
        $('#change_subscription #new_plan_subscription').show();
        $('#change_subscription #new_plan_payment form button span').removeClass('selected-plan');
        $("input[name='new_selected_plan']").attr('value', '');

    }


    $('#change_subscription').on('hidden.bs.modal', function () {
        $('#change_subscription #new_plan_subscription').show();
        $('#change_subscription #new_plan_features').hide();
        $('#change_subscription #new_plan_payment').hide();
        $('#change_subscription #new_plan_features .' + selectedPlanName).hide();
        $('#change_subscription .coupon-discount a').hide();
        $('#new_plan_subscription .modal-body .cs-plans').each(function () {
            var planInputValue = $(this).find('input').attr('value');
            if (planInputValue === ActivePlan) {
                $(this).find('input').prop('checked', true);
            } else {
                $(this).find('input').prop('checked', false);

            }
        });
        $('.modal-footer #continue_plan_change').removeClass(continueStep2);
    });
});


(function($) {
    "use strict"; // Start of use strict

    $(document).ready(function() {
        makeActive();
        $("input[type='radio']").on("change", function() {
            makeActive();
        });
    });

    function makeActive() {
        $(".plan-type input[type='radio']").each(function() {
            if ($(this).prop("checked")) {
                $(this)
                    .closest(".plan-type")
                    .addClass("highlight");
            } else {
                $(this)
                    .closest(".plan-type")
                    .removeClass("highlight");
            }
        });

    }

})(jQuery); // End of use strict

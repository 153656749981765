<template>
    <div class="wrapper see-more">
        <button class="filter-label messages bg-transparent" type="button" data-toggle="collapse" data-target="#messages"
                aria-expanded="false">
            <i class="fas fa-caret-right"></i>
            <span>Messages</span>
        </button>
        <div class="collapse" id="messages">
            <div class="filter-label-content">
                <div class=" conversations-container">
                    <div v-bind:class="{'receiver messager':(messageList.current_user.id !== message.user.id), 'sender messager':(messageList.current_user.id === message.user.id)}" v-for="message in messageList.data">
                        <div class="msg-img d-flex">

                            <img :src="(message.user.user_detail && message.user.user_detail.avatar_url) ? message.user.user_detail.avatar_url : '/img/profile-placeholder.png'" alt="" class="img-circle">
                            <div class="nameDate">
                                <div class="name">{{message.user.name}}</div>
                                <div class="date">{{format_date(message.created_at)}}</div>
                            </div>
                        </div>
                        <div class="msg-txt">{{message.message}}
                            <div class="attached-files" v-for="message_details in message.message_details" >
                                <a :href="'/account/file/?name=' + message_details.attachment + '&type=d'" >{{message_details.detail}}</a>
                            </div>
                        </div>
                    </div>

                </div>
                <form :id="'message_form_' + request_id" class="message-form expand">
                    <div class="form-group message-area">
                          <textarea class="form-control" id="messageText" name="message"
                                    placeholder="Type your message here"
                                    spellcheck="false" @keyup="setValue()"></textarea>
                        <span class="error-msg" id="message_error">please fill message</span>
                    </div>
                    <div class="message-actions">
                        <div class="form-group attach-files mr-3" id="message_file_group">
                            <input name="message_attachment" type="file" multiple="true" class="form-control-file"
                                   id="message_attachment"
                                   data-parsley-max-file-size="5" data-parsley-max-file-count="3"
                                   data-multiple-caption="{count} files selected" data-parsley-id="3">
                            <label for="message_attachment">
                                <span class="mr-2 fileCount" id="messageFileCount"></span>
                                <i class="fas fa-paperclip"></i>
                            </label>
                            <span class="error-msg" id="message_file_error">abc</span>
                        </div>
                        <button type="button" id="send_message" class="btn btn-sm btn-trigger" v-on:click="saveMessage">Send</button>
                    </div>
                </form>
            </div>
        </div>
    </div>
</template>
<script>
    export default {
        name: 'message-modal',
        props: ['request_id'],
        data() {
            return {
                messageList: [],
            }
        },
        mounted() {
            this.setValue();
            this.get_message();
        },
        updated() {
            $(function () {
                $(".message-form").on("click", function(e){
                    $(this).addClass("expand");
                    e.stopPropagation();
                });
                $(document).on("click", function (e) {
                    if ($(e.target).is(".message-form, .message-form *") === false && !$(".message-form textarea").is(":focus")) {
                        $(".message-form").removeClass("expand");
                    }
                });
            });
        },
        methods: {
            setValue(){
                    if($("#messageText").val().length > 0) {
                        $("#send_message").removeAttr('disabled');
                    } else {
                        $("#send_message").attr("disabled", "disabled");
                    }
            },
            get_message(){
                let applicationId = this.request_id;
                let vm = this;
                $.ajax({
                    url: '/account/application/' + applicationId + '/messages',
                    type: 'GET',
                    dataType: 'HTML',

                }).done(function (respnse) {
                    let temp = JSON.parse(respnse);
                    vm.messageList = temp;
                    // console.log(temp);
                });
            },
            format_date(value){
                if (value) {
                    return moment(String(value)).format('MMM DD,YYYY');
                }
            },
            saveMessage:function () {
                let applicationId = this.request_id;

                let form_data = new FormData();
                let file_data = $("#message_attachment").prop('files');
                let vm = this;
                var fileFlag=0;
                $.each(file_data, function (obj, v) {
                    //console.log(v);
                    fileFlag++;
                    var name = 'attachment_'+fileFlag;
                    form_data.append(name, v);
                    //console.log('attachment : '+name);
                });

                form_data.append("application_id", applicationId);
                form_data.append("message", $("#messageText").val());
                $('.ajax-overlay').removeClass('d-none');
                $.ajax({
                    url: '/account/application/send/message',
                    type: 'POST',
                    dataType: 'json',
                    cache: false,
                    contentType: false,
                    processData: false,
                    data: form_data,
                    headers: {
                        'X-CSRF-TOKEN': $('meta[name="csrf-token"]').attr('content')
                    },
                    success: function (response) {
                        topbar.hide();
                        //console.log(response);
                        //show_application_messages(response.data.application_id);

                        $(".message-form").removeClass("expand");
                        $("#messageText").val('');
                        $("#message_attachment").val('');
                        $('#messageFileCount').html('No file choosen');
                        $("#message_send_success").html('Message send successfully')
                       // $sendMessageBtn.prop('disabled', false);
                        vm.get_message();
                        $('.ajax-overlay').addClass('d-none');
                    },
                    error: function (jqXHR) {
                        topbar.hide();
                        var errResponse = JSON.parse(jqXHR.responseText);
                        //console.log(errResponse);
                        $('#send_message').prop('disabled', true);

                        if (errResponse['errors']['message']) {
                            $('#message_group').addClass('form-error')
                            $('#message_error').html(errResponse['errors']['message'][0]);
                        }

                        if (errResponse['errors']['file']) {
                            $('#message_file_group').addClass('form-error')
                            $('#message_file_error').html(errResponse['errors']['file'][0]);
                        }
                        $('.ajax-overlay').addClass('d-none');
                    }
                });
            }
        }
    };
</script>

$(document).ready(function () {
    topbar.config({
        barThickness: 8,
        barColors: {
            '0': 'rgba(26,  188, 156, .7)',
            '.3': 'rgba(41,  128, 185, .7)',
            '1.0': 'rgba(231, 76,  60,  .7)'
        },
        shadowBlur: 5,
        shadowColor: 'rgba(0, 0, 0, .5)'
    });

    $('#applicant_application_filter').on('change', function () {
        if (this.value != '') {
            window.location = route('account.applications', [window.appConfig.subDomain]) + '/?status=' + this.value + '&&application=' + $('#applications_filter').find(":selected").val();
        } else {
            window.location = route('account.applications', [window.appConfig.subDomain]);
        }
    });

    $('#applications_filter').on('change', function () {
        if (this.value != '') {
            window.location = route('account.applications', [window.appConfig.subDomain]) + '/?status=' + $('#applicant_application_filter').find(":selected").val() + '&&application=' + this.value;
        } else {
            window.location = route('account.applications', [window.appConfig.subDomain]);
        }
    });

    $('#request-details').on('show.bs.modal', function (e) {
        topbar.show();
        $.ajax({
            url: route('application.view', [window.appConfig.subDomain, $(e.relatedTarget).data('applicationId')]).url(),
            type: 'GET',
            headers: {'X-CSRF-TOKEN': window.appConfig.csrfToken},
        }).done(function (response) {
            $('#application-detail-content').html(response);

            $('#applicationAssignedTo').select2({
                allowClear: true,
                placeholder: 'Select Approval Manager'
            });

            $('#requestAssignedTo').select2({
                allowClear: true,
                placeholder: 'Select Approval Manager'
            });
            if(canViewTodo){
              $('.todo_dropdown').select2({'data':selectUserData, templateSelection: formatUsersIcon});
            }



            topbar.hide();
        }).fail(function (error) {
            console.log(error);
            topbar.hide();
        });

    });


    $(document).on('click', '#updateApplicationStatus', function (e) {
        //$($('#manage_application').serialize())
        $(this).attr("disabled", "disabled");

        topbar.show();
        $.ajax({
            url: route('application.update.status', [window.appConfig.subDomain, $(this).data('applicationId')]).url(),
            type: 'POST',
            headers: {'X-CSRF-TOKEN': window.appConfig.csrfToken},
            data: $('#manage_application').serialize()
        }).done(function (response) {
            if (response.status) {
                //window.location.reload(true);
                $('.alert-success').remove();
                $('#manage_application').append('<div class="alert alert-success">Updated Successfully.</div>');
                $('#updateApplicationStatus').removeAttr('disabled');
                $('#badge_' + response.id).removeClass().addClass('badge badge-pill ' + response.badge_class).html(response.status_name);
                $('#assigned_to_' + response.id).html(response.assigned_to);
                topbar.hide();
            } else {
                $('#updateApplicationStatus').removeAttr('disabled');
                topbar.hide();
                return false;

            }

        }).fail(function (error) {
            console.log(error);
            topbar.hide();
        });
    });
    $('#edit-request-details').on('shown.bs.modal', function (e) {
        $(this).find('#edit_request').attr('href', $(e.relatedTarget).data('href'));
    });
    $('#archive-request').on('shown.bs.modal', function (e) {
        $(this).find('#archive_user_request').attr('action', $(e.relatedTarget).data('href'));
    });
    $('#unarchive-request').on('shown.bs.modal', function (e) {
        $(this).find('#unarchive_user_request').attr('action', $(e.relatedTarget).data('href'));
    });
});

function formatUsersIcon (users) {
  if (!users.id) {
    return users.text;
  }
  var $users = $(
    '<span class="usr-info"><img/> <span class="usr-name"></span></span>'
  );
  if(users.profileType == 'img'){
        $users.find("span").text(users.name);
        $users.find("img").attr("src",users.userIcon);
      return $users;
    }

  $users.find("span").text(users.userIcon);
  $users.find("img").attr("src",'');
  return $users;
};

(function ($) {
    "use strict"; // Start of use strict

    // Toggle the side navigation
    $("#sidebarToggle, #sidebarToggleTop,#side-toggle").on("click", function (e) {
        console.log("hi");
        $("body").toggleClass("sidebar-toggled");
        $(".sidebar").toggleClass("toggled");
        if ($(".sidebar").hasClass("toggled")) {
            $(".sidebar .collapse").collapse("hide");
        }
    });

    // Close any open menu accordions when window is resized below 768px
    $(window).resize(function () {
        if ($(window).width() < 768) {
            $(".sidebar .collapse").collapse("hide");
            $("body").addClass('sidebar-toggled');
            $(".sidebar").addClass("toggled");
        }
    });

    if ($(window).width() < 768) {
        $("body").addClass('sidebar-toggled');
        $(".sidebar").addClass("toggled");
    }

    // Prevent the content wrapper from scrolling when the fixed side navigation hovered over
    $("body.fixed-nav .sidebar").on("mousewheel DOMMouseScroll wheel", function (
        e
    ) {
        if ($(window).width() > 768) {
            var e0 = e.originalEvent,
                delta = e0.wheelDelta || -e0.detail;
            this.scrollTop += (delta < 0 ? 1 : -1) * 30;
            e.preventDefault();
        }
    });

    // Scroll to top button appear
    $(document).on("scroll", function () {
        var scrollDistance = $(this).scrollTop();
        if (scrollDistance > 100) {
            $(".scroll-to-top").fadeIn();
        } else {
            $(".scroll-to-top").fadeOut();
        }
    });

    // Smooth scrolling using jQuery easing
    $(document).on("click", "a.scroll-to-top", function (e) {
        var $anchor = $(this);
        $("html, body")
            .stop()
            .animate(
                {
                    scrollTop: $($anchor.attr("href")).offset().top - 100
                },
                1000,
                "easeInOutExpo"
            );
        e.preventDefault();
    });

    // uncheck input on doule click
    $(document).ready(function () {
        makeActive();
        $("input[type='radio']").on("change", function () {
            makeActive();
        });
        $(".selectpicker").selectpicker();
        $(".desc-section .due_datepicker").datepicker({
            dateFormat: "d M yy",
            firstDay: 1,
        });
        // if no date is selected then display todays date
        if ($(".desc-section .due_datepicker").datepicker('getDate') === null) {
            $(".desc-section .due_datepicker").datepicker().datepicker('setDate', 'today');
        }
        //task due date
        $(".action-items .due_datepicker").datepicker({
            dateFormat: "d M",
            firstDay: 1,
            onSelect: function (dateText) {
                var selectedDate = $(this).datepicker('getDate');
                var duedateId = $(this).attr("id");
                $("#" + duedateId + '_hidden').val($.datepicker.formatDate("yy-mm-dd", selectedDate));
                $("#" + duedateId).val(dateText);
                // $("#" + duedateId).change()
                var now = new Date();
                now.setHours(0, 0, 0, 0);
                $(this).parent().addClass("date-selected");
                if (selectedDate < now) {
                    $(this).addClass('passed-due-date');
                } else {
                    $(this).removeClass('passed-due-date');
                }
            }
        });
        // check to see if due date has passed
        $('.due_datepicker').datepicker().on('change', function () {
            var selectedDate = $('.due_datepicker').datepicker('getDate');
            var now = new Date();
            now.setHours(0, 0, 0, 0);
            if (selectedDate < now) {
                $(this).addClass('passed-due-date');
            } else {
                $(this).removeClass('passed-due-date');
            }
        });

    });

    function makeActive() {
        $(".plan-type input[type='radio']").each(function () {
            if ($(this).prop("checked")) {
                $(this)
                    .closest(".plan-type")
                    .addClass("highlight");
            } else {
                $(this)
                    .closest(".plan-type")
                    .removeClass("highlight");
            }
        });

        // choose file

        function readURL(input) {
            if (input.files && input.files[0]) {
                var reader = new FileReader();
                reader.onload = function (e) {
                    $(".imagePreview").css(
                        "background-image",
                        "url(" + e.target.result + ")"
                    );
                    $(".imagePreview").hide();
                    $(".imagePreview").fadeIn(650);
                };
                reader.readAsDataURL(input.files[0]);
            }
        }

        $(".imageUpload").change(function () {
            readURL(this);
        });
    }

    /* Multiple Item Picker */
    // selectbox
    //

    // request filter
    $("#filterBtn").on("click", function () {
        $("#requestFilters").toggleClass("show");
    });

    // seemore in filters
    $(".additonal-form-filters").slideUp();
    $("#seeMore").on("click", function () {
        var txt = $(this).prev().find(".additonal-form-filters").is(":visible") ? "See More" : "See Less";
        $(this).text(txt);
        $(this).prev().find(".additonal-form-filters").slideToggle(200);
    });

    //inline editing of request details
    $(function () {
        $('.edit-data').click(function (e) {
            $(this).addClass('edit-mode');
            $(this).siblings(".collapse").find('.box').attr('contenteditable', 'true');
            e.stopPropagation();
        });
        $(document).on("click", function (e) {
            if ($(e.target).is(".filter-label-content *,.dropdown-item") === false && $(".edit-data").hasClass("edit-mode")) {
                $('.req-data .box').removeAttr('contenteditable');
                $(".edit-data").addClass('edit-mode');
            }
        });
    });

    // dropdown menu actions
    $(".top-options #editRequest").on('click', function () {
        //edit request
        $(this).parents('.request-desc-wrapper').find('.edit-data').trigger("click");
        $(this).parents('.request-desc-wrapper').find('.box').attr('contenteditable', 'true');
    });

    //message form
    $(function () {
        $(".message-form").on("click", function (e) {
            $(this).addClass("expand");
            e.stopPropagation();
        });
        $(document).on("click", function (e) {
            if ($(e.target).is(".message-form, .message-form *") === false && !$(".message-form textarea").is(":focus")) {
                $(".message-form").removeClass("expand");
            }
        });
    });


    //open message desc
    $('body').on("click", '.request-action .more-desc,.request-action .add-message', function (e) {
        var id = $(this).attr("data-target");
        e.preventDefault();
        //$(this).parents(".requests-container").addClass("selected");
        $(id).addClass("slide");
        $(".requests-wrapper").addClass("expanded");
        $("#content-wrapper").addClass("expanded");
        if ($(this).hasClass("add-message")) {
            $(".filter-label.messages").trigger('click');
            $(".slide").find(".message-form textarea").focus();
            $(".slide").find(".message-form").addClass("expand");
        }
        if ($(this).hasClass("more-desc")) {
            $(".filter-label.reqDetails").trigger('click');
        }
    });

    $('body').on('click', '.requests-container', function (e) {
        var id = $(this).attr("data-target");
        e.preventDefault();
        if ($(e.target).is(".request-status,.request-status *, .request-assign, .request-assign *,.request-action, .request-action *,.td-wrap *") === false) {
            //close all others
            if ($(".requests-wrapper").hasClass("expanded")) {
                var sid = $(".requests-container.selected").attr("data-target");
                $(this).removeClass("expanded");
                $(sid).removeClass("slide");
                //$(".requests-container").removeClass("selected");
            }
            //open the one clicked
            //$(this).addClass("selected");
            $(id).addClass("slide");
            $(".requests-wrapper").addClass("expanded");
        }
    });

    //tooltip
    $(".with-tooltip").hover(
        function () {
            $(this).addClass("visible");
        },
        function () {
            $(this).removeClass("visible")
        }
    );

    $(".due_datepicker").on("focus", function () {
        $(this).parents(".with-tooltip").removeClass("visible");
    });

    $(".with-tooltip *").on("click", function () {
        $(this).parents(".with-tooltip").removeClass("visible");
    });


    //datepicker


    //************* to-do section js ************** //

    //add to do


    //note desc excerpt
    var todoCommentLimit = 35;
    $(document).on('hidden.bs.collapse', '#todo-section .todo-collapse', function () {
        var collpaseDataid = $(this).data('id'),
            explodecollpaseDataid = collpaseDataid.split('_');
        var textareaValue = $(this).find('textarea.todo_textarea').val();
        if (textareaValue.length > todoCommentLimit) {
            var readMoreSpan = '<span class = "btn btn-link p-0" data-toggle="collapse"  data-target="#todoCollapse_' + explodecollpaseDataid[2] + '"> Read More </span>';
            $('#note_desc_' + explodecollpaseDataid[2]).html(textareaValue.substring(0, todoCommentLimit) + "..." + readMoreSpan);
        } else {
            $('#note_desc_' + explodecollpaseDataid[2]).html(textareaValue.substring(0, todoCommentLimit));
        }
    });

    $(document).on('shown.bs.collapse', '#todo-section .todo-collapse', function () {
        $(this).siblings(".note").empty();
    });
    $(document).click(function (event) {
        if (!$(event.target).is('.todo-section *')) {
            $('.todo-row .collapse').collapse('hide');
        }
    });


    //complete to-do
    var completeStatus = 0;
    $("#todo-section button.btn-complete").on('click', function (e) {
        e.preventDefault();
        if ($(this).hasClass('add_todo_checkbox')) {
            completeStatus = (completeStatus === 0) ? 1 : 0;
        }
        $(this).parent('form').toggleClass('todo-completed');
    });

    // assign user

    //************* /to-do section js************** //


// Add tags

//tags inputs take width of content inside it
    $.fn.textWidth = function (text, font) {
        if (!$.fn.textWidth.fakeEl) $.fn.textWidth.fakeEl = $('<span>').hide().appendTo(document.body);
        $.fn.textWidth.fakeEl.text(text || this.val() || this.text(), font || this.css('font'));
        return $.fn.textWidth.fakeEl.width();
    };

    $('.edit_tag_input').on('input', function () {
        var inputWidth = $(this).textWidth() + 8;
        $(this).css({
            width: inputWidth
        })
    }).trigger('input');

    function inputWidth(elem, minW, maxW) {
        elem = $(this);
        console.log(elem)
    }

    inputWidth($('.edit_tag_input'));


//delete to-do
    $(".delete-todo").on("click", function () {
        $(this).parents(".todo-row").remove();
    });
//close btn
    $('body').on('click', '.top-options .close', function (e) {
        e.preventDefault();
        $(".request-desc-wrapper").removeClass("slide");
        $(".requests-container").removeClass("selected");
        $("#content-wrapper").removeClass("expanded");
        $(".collapse.show").collapse('hide');
    });

    // add request page classs

    $(".new-requests-tab").each(function (el) {
        $('body').addClass('request-page')
    });

})(jQuery); // End of use strict

// custom input file

var inputs = document.querySelectorAll(".requestActions .form-control-file,.message-form .form-control-file");
Array.prototype.forEach.call(inputs, function (input) {
    var label = input.nextElementSibling,
        labelVal = label.innerHTML;

    input.addEventListener("change", function (e) {
        var fileName = "";
        if (this.files && this.files.length > 1)
            fileName = (this.getAttribute("data-multiple-caption") || "").replace(
                "{count}",
                this.files.length
            );
        else fileName = e.target.value.split("\\").pop();

        if (fileName) label.querySelector("span").innerHTML = fileName;
        else label.innerHTML = labelVal;
    });
});

import Clipboard from 'clipboard';

(function ($) {
    "use strict"; // Start of use strict
    let clipboard = new Clipboard('.copy-clipbord');

    clipboard.on('success', function (e) {
        $('.copy-message').remove();
        $('#show_form_url').find('.modal-body').append('<div class="copy-message alert alert-success">Copied Successfully.</div>');

        // e.clearSelection();
    });
    //index page
    $('#delete_request_form').on('show.bs.modal', function (e) {
        $(this).find('#delete_form').data('formId', $(e.relatedTarget).data('formId'));
        $(this).find('#delete_form').data('churchId', $(e.relatedTarget).data('churchId'));
    });

    $('#toggle_status_of_form').on('show.bs.modal', function (e) {
        $(this).find('#toggle_form_status').data('formId', $(e.relatedTarget).data('formId'));
        $(this).find('#toggle_form_status').data('churchId', $(e.relatedTarget).data('churchId'));
    });

    $('#show_form_url').on('show.bs.modal', function (e) {
        $('.copy-message').remove();
        $('#form_url_value').val(route('form.view', [window.appConfig.subDomain, $(e.relatedTarget).data('churchId'), $(e.relatedTarget).data('formId')]).url());
    });

    $('#activate_free_form_template').on('show.bs.modal', function (e) {
        //alert()
        if ($(e.relatedTarget).data('allowUrl') > 0) {
            $(this).find('#activate_free_form').remove();
            if($(e.relatedTarget).data('totalForms') > 1) {
                $(this).find('.modal-body').html('You can only have 3 active Forms');
            }else{
                $(this).find('.modal-body').html('You can only use one template');
            }

        } else {
            $(this).find('#activate_free_form').data('formId', $(e.relatedTarget).data('formId'));
            $(this).find('#activate_free_form').data('churchId', $(e.relatedTarget).data('churchId'));
        }

    });

    $('#toggle_status_of_form').on('click', '#toggle_form_status', function (e) {
        $.ajax({
            url: route('form.status', [window.appConfig.subDomain, $(this).data('churchId'), $(this).data('formId')]).url(),
            type: 'POST',
            headers: {'X-CSRF-TOKEN': window.appConfig.csrfToken},
        }).done(function (response) {
            console.log(response);
            window.location.reload(true);
        }).fail(function (error) {
            console.log(error);
        })

    });


    $('#attachment_delete_modal').on('show.bs.modal', function (e) {
        console.log($(e.relatedTarget).data('attachment-id'));
        $(this).find('#confirm-delete-attachment').data('attachment-id', $(e.relatedTarget).data('attachment-id'));
       console.log($(this).find('#delete-application-attatchment'));
    });

    $('#confirm-delete-attachment').on('click', function(e){
        e.preventDefault();
       $.ajax({
          url: route('application.deleteFile',[window.appConfig.subDomain, $(this).data('attachment-id')]).url(),
          type: 'POST',
          headers: {'X-CSRF-TOKEN': window.appConfig.csrfToken},
       }).done(function (response) {
           console.log(response);
           location.reload();
       }).fail(function (error) {
           console.log(error);
       })
    });

    $('#delete_request_form').on('click', '#delete_form', function (e) {
        $.ajax({
            url: route('form.delete', [window.appConfig.subDomain, $(this).data('churchId'), $(this).data('formId')]).url(),
            type: 'POST',
            headers: {'X-CSRF-TOKEN': window.appConfig.csrfToken},
        }).done(function (response) {
            console.log(response);
            location.reload();
        }).fail(function (error) {
            console.log(error);
        })

    });

    $('#draft-request-details').on('show.bs.modal', function (e) {
        $(this).find('#continue_with_draft').data('formId', $(e.relatedTarget).data('formId'));
        $(this).find('#continue_with_draft').data('churchId', $(e.relatedTarget).data('churchId'));
    });

    $('#draft-request-details').on('shown.bs.modal', function (e) {
        var startAgainLink = $(e.relatedTarget).data('link');
        var formId = $(e.relatedTarget).data('form-id');
        var churchId = $(e.relatedTarget).data('church-id');
        var applicationId = $(e.relatedTarget).data('application-id');
        var url = route('form.editdraft', [window.appConfig.subDomain, churchId, applicationId]).url();
        $(this).find('#continue_with_draft').attr('href', url);
        $(this).find('#reset_user_draft').attr('action', startAgainLink);
    });

    $('#activate_free_form_template').on('click', '#activate_free_form', function (e) {
        $.ajax({
            url: route('form.activate.free.form', [window.appConfig.subDomain, $(this).data('churchId'), $(this).data('formId')]).url(),
            type: 'POST',
            headers: {'X-CSRF-TOKEN': window.appConfig.csrfToken},
        }).done(function (response) {
            console.log(response);
            window.location.reload(true);
        }).fail(function (error) {
            console.log(error);
        })

    });

    //create form page/duplicate form/edit form page
    $(document).ready(function () {
        var options = {
            formData: window.appConfig.formSetting || '',
            dataType: 'json',
            disableFields: ['autocomplete', 'hidden', 'header', 'number', 'paragraph', 'button'],
            roles: false,
            showActionButtons: false,
            fieldRemoveWarn: true, // or defaults to false
            disableHTMLLabels: true,
            //disabledActionButtons: ['data','save'],
            disabledSubtypes: {
                text: ['password', 'color'],
                textarea: ['tinymce', 'quill'],
                file: ['fineuploader'],
            },
            disabledAttrs: [
                'access', 'description', 'inline', 'max', 'other', 'step', 'toggle', 'className', 'name'
            ]
        };
        let formBuilder = $('#church_form_builder').formBuilder(options);

        //save as draft
        $('#saveDraftForm').on('click', function (e) {
            $('.error-msg').remove();
            let alphaSpace = /^[a-zA-Z0-9\s]+$/;
            let formData = formBuilder.actions.getData('json', true);
            //debugger;
            let errorFlag = false;
            let updateFormData = $.each(JSON.parse(formData), function (idx, obj) {
                if (obj.type == 'select' || obj.type == 'radio-group' || obj.type == 'checkbox-group') {
                    $.each(obj.values, function (index, subObj) {
                        if (subObj.value == '') {
                            subObj.value = subObj.label
                        }
                        if (subObj.label == '' && subObj.value != '') {
                            subObj.label = subObj.value
                        }
                        if (subObj.label == '' && subObj.value == '') {
                            errorFlag = true;
                        }
                    })
                }
            });

            formData = JSON.stringify(updateFormData);
            if ($.trim($('#name').val()) === '') {
                $('#form-group-name').addClass('form-error');
                $('#name').after('<span class="error-msg">The name field is required.</span>');
                return false;
            } else if (!alphaSpace.test($.trim($('#name').val()))) {
                $('#form-group-name').addClass('form-error');
                $('#name').after('<span class="error-msg">The name field format is not valid.</span>');
                return false;
            } else if ($.trim($('#name').val()).length > 100) {
                $('#form-group-name').addClass('form-error');
                $('#name').after('<span class="error-msg">The name field allowed only 100 Char.</span>');
                return false;
            } else if (errorFlag) {
                $('#form-group-name').addClass('form-error');
                $('#saveDraftForm').before('<div class="error-msg alert alert-danger form-success-alert">Some of the fields have blank options. please check form fields.</div>');
                return false;
            } else if (formData === '[]') {
                $('#church_form_builder').addClass('form-error');
                $('#church_form_builder').after('<span class="error-msg">The Form field is required</span>');
                return false;
            } else {
                $('#saveDraftForm').prop('disabled', true);
                $('#create-request-form').find('input#formSettingsJson').val(formData);
                $('#create-request-form').find('input#published').val(0);
                // Delay form submit event so that form field is well set before submit
                setTimeout(function () {
                    console.log('Submitting form...');
                    $('#create-request-form').trigger('submit');
                }, 300);
            }
        });
        //save and publish
        $('#savePublishForm').on('click', function (e) {
            $('.error-msg').remove();
            let alphaSpace = /^[a-zA-Z0-9\s]+$/;
            let formData = formBuilder.actions.getData('json', true);

            //debugger;
            let errorFlag = false;
            let updateFormData = $.each(JSON.parse(formData), function (idx, obj) {
                if (obj.type == 'select' || obj.type == 'radio-group' || obj.type == 'checkbox-group') {
                    $.each(obj.values, function (index, subObj) {
                        if (subObj.value == '') {
                            subObj.value = subObj.label
                        }
                        if (subObj.label == '' && subObj.value != '') {
                            subObj.label = subObj.value
                        }
                        if (subObj.label == '' && subObj.value == '') {
                            errorFlag = true;
                        }
                    })
                }
            });

            formData = JSON.stringify(updateFormData);
            if ($.trim($('#name').val()) === '') {
                $('#form-group-name').addClass('form-error');
                $('#name').after('<span class="error-msg">The name field is required.</span>');
                return false;
            } else if (!alphaSpace.test($.trim($('#name').val()))) {
                $('#form-group-name').addClass('form-error');
                $('#name').after('<span class="error-msg">The name field format is not valid.</span>');
                return false;
            } else if ($.trim($('#name').val()).length > 100) {
                $('#form-group-name').addClass('form-error');
                $('#name').after('<span class="error-msg">The name field allowed only 100 Char.</span>');
                return false;
            } else if (formData === '[]') {
                $('#church_form_builder').addClass('form-error');
                $('#church_form_builder').after('<span class="error-msg">The Form field is required</span>');
                return false;
            } else if (errorFlag) {
                $('#form-group-name').addClass('form-error');
                $('#saveDraftForm').before('<div class="error-msg alert alert-danger form-success-alert">Some of the fields have blank options. please check form fields.</div>');
                return false;
            } else {
                $('#savePublishForm').prop('disabled', true);
                $('#formSettingsJson').val(formData);
                $('#published').val(1);
                // Delay form submit event so that form field is well set before submit
                setTimeout(function () {
                    console.log('Submitting form...');
                    $('#create-request-form').trigger('submit');
                }, 300);
            }
        });

        const churchFormRender = $("#church_form_render");
        let $form = $('form#create-request-form');
        let formRender = $(churchFormRender).formRender(options);
        if ($form.find($('input[type="file"]')).length > 0) {
            $form.find($('input[type="file"]')).attr('data-parsley-max-file-size', 25);
            $form.find($('input[type="file"]')).attr('data-parsley-max-file-count', 3);
        }

        window.Parsley.addValidator('maxFileSize', {
            validateString: function (_value, maxSize, parsleyInstance) {
                if (!window.FormData) {
                    alert('You are making all developpers in the world cringe. Upgrade your browser!');
                    return true;
                }
                let files = parsleyInstance.$element[0].files;
                let flag = true;
                for (let i = 0, file; file = files[i]; i++) {
                    if (file.size > maxSize * 1024 * 1024) {
                        flag = false;
                    }
                }
                return flag;
            },
            requirementType: 'integer',
            messages: {
                en: 'Each file should not be larger than %s mb.',
            }
        });

        window.Parsley.addValidator('maxFileCount', {
            validateString: function (_value, maxCount, parsleyInstance) {
                if (!window.FormData) {
                    alert('You are making all developpers in the world cringe. Upgrade your browser!');
                    return true;
                }
                let files = parsleyInstance.$element[0].files;
                return files.length <= maxCount;

            },
            requirementType: 'integer',
            messages: {
                en: 'Only %s files are allowed to upload.',
            }
        });


        $('#addRequest').on('click', function (e) {
            if ($form.parsley().validate()) {
                topbar.show();
                $('#setting').val(window.JSON.stringify($(churchFormRender).formRender("userData")));
                $(this).attr("disabled", "disabled");
                setTimeout(function () {
                    $form.submit();
                }, 300);
                topbar.hide();
            }
            // return false;
        });

        $('#updateRequest').on('click', function (e) {
            if ($form.parsley().validate()) {
                topbar.show();
                $('#setting').val(window.JSON.stringify($(churchFormRender).formRender("userData")));
                $(this).attr("disabled", "disabled");
                setTimeout(function () {
                    $form.submit();
                }, 300);
                topbar.hide();
            }
            // return false;
        });


        //add action from form to save continue later modal
        $('#save-continue-request').on('show.bs.modal', function (e) {
            $(this).find('#save-for-later').data('action', $(e.relatedTarget).data('action'));
        });

        $('#saveForLater').on('click', function (e) {
            $('#create-request-form').parsley().destroy();
            topbar.show();
            $('#setting').val(window.JSON.stringify($(churchFormRender).formRender("userData")));
            $(this).attr("disabled", "disabled");
            $('#church_form_render .rendered-form').append('<input class="d-none" type="text" name="save_and_continue_later" value="1">');
            setTimeout(function () {
                $form.submit();
            }, 300);
            topbar.hide();
        });


        $('#create-request-form').find('input[type=date]').each(function () {
            if ($(this)[0].type != 'date') $(this).datepicker({
                dateFormat: 'yy-mm-dd',
            });
        });

    });
})(jQuery); // End of use strict

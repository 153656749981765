<template>
    <div class="tags-input-container">
        <span class="tag-btn with-tooltip">
                          <i class="fas fa-tag"></i>
                          <div class="tooltip">Add Tags</div>
                        </span>
            <vue-tags-input
                v-model="tag" :tags="tags"
                :autocomplete-items="autocompleteItems"
                :avoid-adding-duplicates="true"
                ref="inputTag"
                @tags-changed="newTags => tags = newTags"
                @before-adding-tag="saveInDatabase"
                @before-deleting-tag="removeTag"
            />

    </div>
</template>

<script>
    import VueTagsInput from '@johmun/vue-tags-input'
    export default {
        props: ['request_id'],
        components: { VueTagsInput },
        data(){
            return {
                tag: '',
                tags: [],
                autocompleteItems: [],
            }
        },
        watch: {
            'tag': 'initItems',
        },
        mounted() {

            axios.get('/tag', {
                params: {
                    application_id: this.request_id
                }
            }).then(response => {
                this.tags = response.data.map(a => {
                    return { id: a.id, text: a.title };
                }); }).catch(() => console.warn('Oh. Something went wrong'));
        },

        methods: {

            initItems() {
                if (this.tag.length < 2) return;
                const url = `/tag/search`;
                clearTimeout(this.debounce);
                let searchStr = this.tag;
                //console.log(searchStr);
                this.debounce = setTimeout(() => {
                    axios.get(url, {
                        params: {
                            search: searchStr
                        }
                    }).then(response => {
                        this.autocompleteItems = response.data.map(a => {
                            return { id: a.id, text: a.title };
                        }); }).catch(() => console.warn('Oh. Something went wrong'));
                }, 600);
            },
            saveInDatabase(obj){
                if(obj.tag.id)
                {
                    $.ajax({
                        url: '/tag/apptag' ,
                        type: 'POST',
                        headers: {'X-CSRF-TOKEN': window.appConfig.csrfToken},
                        data: { id: obj.tag.id,
                            application_id: this.request_id },
                        dataType:'HTML',
                    }).done(function (response) {
                        obj.addTag();
                        this.autocompleteItems = [];
                    }).fail(function (error) {
                        obj.deleteTag();
                    });
                }
                else{
                    $.ajax({
                        url: '/tag/save' ,
                        type: 'POST',
                        headers: {'X-CSRF-TOKEN': window.appConfig.csrfToken},
                        data: { text: obj.tag.text,
                            application_id: this.request_id},
                        dataType:'HTML',
                    }).done(function (response) {
                        obj.addTag();
                    }).fail(function (error) {
                        obj.deleteTag();
                    });
                }
                
            },
            removeTag(obj){
               // console.log(obj.tag);
                axios.post('/tag/remove', {
                    tag_id:  obj.tag.id,
                    application_id: this.request_id,
                }).then(function (response) {
                    //console.log(response);
                }).catch(err => {
                    //console.log(err)
                });

                obj.deleteTag();
            }
        }
    }
</script>

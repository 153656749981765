
$(document).ready(function(){
    var oldRequestScript;
   $('#delete_script_modal').on('show.bs.modal', function (e) {
       $(this).find('#delete_script').data('trackid', $(e.relatedTarget).data('trackid'));
   });

   $('#delete_script_modal').on('click', '#delete_script', function (e) {
       // send value by ajax to server
       $.ajax({
           url: route('admin.tracker.delete', [$(this).data('trackid')]).url(),
           type: 'POST',
           headers: {'X-CSRF-TOKEN': window.appConfig.csrfToken},
       }).done(function (response) {
           window.location.reload(true);
       }).fail(function (error) {
           console.log(error);
       })
   });

   /***
    * 
    *  When Add Script on url from create/Edit Page in Header Tracking
    * 
    */
   showHideUrlSection($("#addHeaderTrackingScript input[name = 'type']:checked").val());
   
   
   function showHideUrlSection(type)
   {
       if(type == 1)
       {
           $("#urls_group").hide(); 
           if((typeof oldRequestScript !== "undefined") && (oldRequestScript == 0)){
            getHeaderScript('/',type);  
           }else{
            if(typeof globalScript !== "undefined"){
                $("#script_textarea").text(globalScript);
            }
           }
       }
       else if(type == 2)
       {
           $("#urls_group").show();    
       }

       oldRequestScript = 0;
   }

   $("#addHeaderTrackingScript input[name = 'type']").click(function(){
           var selectedType = $("#addHeaderTrackingScript input[name = 'type']:checked").val();
           if(selectedType == 2)
           {
               $("#script_textarea").text('');
               $("#url").val($("#url option:first").val());
           }
           showHideUrlSection(selectedType);

   });

   $(document).on('click', "#reset", function(){
       
        window.location.href = route("admin.tracker") ;
   });


   $(document).on("change", "#url", function(){
       $("#url option").attr('disabled','disabled');
       var optionSelected = $("option:selected", this);
       var valueSelected = this.value;
       getHeaderScript(valueSelected,2);
   });

    function getHeaderScript(getUrl, type=1)
    {
       $.ajax({
           url : route('admin.tracker.geturlscript.ajax').url(),
           type : 'GET',
           data : {'url': getUrl, 'type' :type }
       }).done(function(response){
           var finalText = '';
           $.each(response, function(index,val){
               finalText = finalText + val+'\n';
           });
           $("#script_textarea").text(finalText);
           $("#url option").removeAttr('disabled');
       }).fail(function(error){
           console.log(error);
           $("#url option").removeAttr('disabled');
       }) 
    }

});

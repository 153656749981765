<template>
    <div id="todo-section" class="todo-section">
        <div id="addtodo_section"> </div>
        <div id="todolist_details">
            <div id="todocontainer">
                <!-- <div class="alert" id = "todo_alert" style="display:none">  </div> -->
                <div class="edit_todo_section" v-for="(todo, key) in todoListData" >
                    <form :data-application-id="application_id" :id="'edit_todo_form_' + todo.id" v-bind:class="{'todo-row row align-items-center':true, 'todo-completed':(todo.status === 1)}" >
                        <button class="btn-complete todo_status with-tooltip mb-2" :id="'todo_checkbox_' + todo.id" :data-value="todo.id">
                            <i class="far fa-check-circle"></i>
                            <div class="tooltip">Mark as complete</div>
                        </button>
                        <input type="text" name="todo_id" v-model="todo.id" class="d-none">

                        <div class="title h-100 px-2 mb-2">
                            <input type="text" v-model="todo.title" class="edit_todo_input w-100" name="title"
                                    placeholder="Enter Title" v-on:blur="saveTodo(todo.id)">
                        </div>

                        <div class="note px-2 px-lg-4 mb-2" :id="'note_desc_edit-' + todo.id">
                        </div>

                        <div class="action-items d-flex mb-2">
                            <div class="due-date-wrap" :class="{'px-2 with-tooltip':true }" :for="'todo_dueDate_' + todo.id">
                                <span v-if="todo.end_date == ''" class="position-absolute cal-icon">
                                    <svg version="1.1"  xmlns="http://www.w3.org/2000/svg" viewBox="0 0 426.667 426.667">
                                    <path d="M362.667,42.667h-21.333V0h-42.667v42.667H128V0H85.333v42.667H64c-23.573,0-42.453,19.093-42.453,42.667L21.333,384c0,23.573,19.093,42.667,42.667,42.667h298.667c23.573,0,42.667-19.093,42.667-42.667V85.333
                                    C405.333,61.76,386.24,42.667,362.667,42.667z M362.667,384H64V149.333h298.667V384z" />
                                    <polygon points="309.973,214.613 287.36,192 183.253,296.107 138.027,250.88 115.413,273.493 183.253,341.333" />
                                    </svg>
                                </span>
                                <datepicker
                                            :value="todo.actual_end_date"
                                            :id="'todo_dueDate_' + todo.id"
                                            name="end_date"
                                            v-bind:input-class="{'due_datepicker edit_todo_input w-100':true, 'passed-due-date':isgreater(todo.actual_end_date)}"
                                            calendar-class=""
                                            ref="datepicker"
                                            format="d MMM"
                                            @selected="getDatepicker($event, todo.id)"></datepicker>
                                <input type="hidden" :id="'todo_dueDate_' + todo.id + '_hidden'" v-model="todo.actual_end_date" name="enddate">
                                <div class="tooltip">Add due date.</div>
                            </div>

                            <div class="assign_user px-2 with-tooltip">
                                <select :id="'todo_assign_user_' + todo.id" v-model="todo.assinged_user_id" name="assign_user" class="selectpicker" @change="saveTodo(todo.id)" data-live-search="true">
                                    <option :data-icon="todoUser.profileType =='img' ?  '<span class=\'usr-info\'><img src=\'' + todoUser.userIcon + '\'><span class=\'usr-name\'></span></span>' : '<span class=\'usr-info\'><img> <span class=\'usr-name\'>' + todoUser.userIcon + '</span></span>'" v-for="todoUser in getTodoUser" :value="todoUser.id" >{{todoUser.name}}</option>
                                </select>
                                <div class="tooltip">Assign a user.</div>
                            </div>

                            <div class="comment-wrap px-2" data-toggle="collapse" :data-target="'#todoCollapse_edit-' + todo.id" title="Add Note">
                                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="77.5" height="73.1" viewBox="0 0 77.5 73.1">
                                    <path class="st0" d="M72.6,59.9H4.9C2.2,59.9,0,57.7,0,55V4.9C0,2.2,2.2,0,4.9,0h67.7c2.7,0,4.9,2.2,4.9,4.9V55
                                                    C77.5,57.7,75.3,59.9,72.6,59.9z" />
                                    <polygon class="st0" points="12.7,72.1 12.7,58.9 30.1,58.9 " />
                                    <rect x="21.4" y="17.4" fill="#ffffff" width="34.5" height="8.1" />
                                    <rect x="21.4" y="31.8" fill="#ffffff" width="25.9" height="8.1" />
                                </svg>
                                <div class="tooltip">Add a comment.</div>
                            </div>
                            <div class="delete-todo px-2" title="Delete To-do" v-on:click="removeTodo(key,todo.id)">
                                <svg version="1.1" viewBox="0 0 14 18" xmlns="http://www.w3.org/2000/svg">
                                    <path d="M1,16 C1,17.1 1.9,18 3,18 L11,18 C12.1,18 13,17.1 13,16 L13,4 L1,4 L1,16 L1,16 Z M14,1 L10.5,1 L9.5,0 L4.5,0 L3.5,1 L0,1 L0,3 L14,3 L14,1 L14,1 Z" id="Shape" />
                                </svg>
                                <div class="tooltip">Delete to-do.</div>
                            </div>
                        </div>

                        <div class="collapse todo-collapse w-100" data-id="todo_collpase_edit-12" :id="'todoCollapse_edit-' + todo.id">
                            <div class="card card-body py-1 px-2">
                                <textarea class="w-100 todo_textarea  edit_todo_input" placeholder="Enter Note" :id="'noteDesc' + todo.id" v-on:blur="saveTodo(todo.id)" name="note">{{todo.note}}</textarea>
                            </div>
                        </div>
                    </form>
                </div>
            </div>
        </div>
    </div>
</template>
<script>
    import Datepicker from 'vuejs-datepicker';
    export default {
        name: 'todo-modal',
        props: ['request_id'],
        components: {
            Datepicker
        },
        data() {
            return {
                todoListData: [],
                getTodoUser: [],
                blankTodo:{},
                application_id: null,
                approvalManagers:{},
            }
        },
        mounted() {
            let applicationId = this.request_id;
            this.application_id=this.request_id;
            this.approvalManagers = this.$attrs.approvalManager;
            $('.ajax-overlay').removeClass('d-none');
            let vm = this;
            $.ajax({
                url : '/' + applicationId +'/todo' ,
                type : 'GET',
                dataType : 'HTML'
            }).done(function(respnse){
                $('.ajax-overlay').addClass('d-none');
                let temp = JSON.parse(respnse);
                vm.todoListData = temp.todoListData;
                vm.getTodoUser = temp.getTodoUser;
            });
        },
        updated() {
            let tempThis = this;

            $(".selectpicker").selectpicker('refresh');
            //{ template: "<span class='usr-info'><snap class='usr-name'>UV</snap></span>"}
            $('select.selectpicker').on('change', function(){
                var temp = $(this).next().find("div.filter-option-inner-inner");
                if(temp != undefined){
                    var contentDiv = $(temp).find("i");
                    var myClass = $(contentDiv).attr("class");
                    temp.prepend(myClass);
                }
            });

            $('select.selectpicker').each(function() {

                var temp = $(this).next().find("div.filter-option-inner-inner");
                if(temp != undefined){
                    var contentDiv = $(temp).find("i");
                    var myClass = $(contentDiv).attr("class");
                    temp.prepend(myClass);
                }
            });

        },
        methods: {
            isgreater: function(actualDate){
               return this.$parent.isgreater(actualDate);
            },
            openPicker: function() {
                //this.$refs.datepicker.
                //this.$refs.datepicker.querySelector('input').focus();
                //this.$el.querySelector('due_datepicker').click();
               // this.$refs.datepicker.$children[0].$emit('showCalendar')
            },
            getDatepicker: function(selectDate, id){
                let i =0;
                //console.log(id);
                function appendLeadingZeroes(n){
                    if(n <= 9){
                        return "0" + n;
                    }
                    return n
                }
                let newDate = new Date(selectDate);
                let formatted_date = newDate.getFullYear() + "-" + appendLeadingZeroes(newDate.getMonth() + 1) + "-" + appendLeadingZeroes(newDate.getDate());
                let temp = '#todo_dueDate_' + id + "_hidden";
                $(temp).val(formatted_date);
                for(i =0;i <this.todoListData.length; i++){
                     if(this.todoListData[i].id == id){
                        this.todoListData[i].actual_end_date =formatted_date;
                         this.todoListData[i].end_date =selectDate;
                    }
                }
                this.saveTodo(id);
                var now = new Date();
                now.setHours(0, 0, 0, 0);
                    if (newDate < now) {
                        $("#todo_dueDate_" + id).addClass('passed-due-date');
                    }
                    else {
                        $("#todo_dueDate_" + id).removeClass('passed-due-date');
                    }
            },
            addTodo: function(){
                let tempThis = this;
                let applicationId = this.request_id;
                let counter = this.todoListData.length;
                let formdata = 'complete=0';
                let todoId=0;
                $.ajax({
                    url: '/'+ applicationId +'/todo' ,
                    type: 'POST',
                    headers: {'X-CSRF-TOKEN': window.appConfig.csrfToken},
                    data: formdata,
                    dataType:'HTML',
                }).done(function (response) {
                    console.log(response);
                    let temp = JSON.parse(response);
                    todoId=temp.todoId;
                    console.log("saved todo --" + todoId);
                    tempThis.todoListData.unshift({     //unshift - to push the new object at first place, push will append
                        actual_end_date: "",
                        application_id: applicationId,
                        assigned_user_id: null,
                        created_at: null,
                        created_users_id: null,
                        end_date: "",
                        id: todoId,
                        note: null,
                        start_date: null,
                        status: 0,
                        title: null,
                        updated_at: null,
                    });
                    $(".selectpicker").selectpicker('refresh');
                }).fail(function (error) {
                    //console.log(error);
                    $('.ajax-overlay').addClass('d-none');
                });
                //this.todoListData.push();
            },
            removeTodo(key,id){
                let todo = this.todoListData;
                if(id != '' && id != null) {
                    $('.ajax-overlay').removeClass('d-none');
                    if (confirm("Do you really want to delete?")) {
                        this.$delete(this.todoListData,key);
                        axios.post('/todo/remove', {
                            todo_id: id,
                        }).then(function (response) {
                            //console.log(response);

                            $(".selectpicker").selectpicker('refresh');

                            $('.ajax-overlay').addClass('d-none');
                        }).catch(err => {
                            // console.log(err)
                            $('.ajax-overlay').addClass('d-none');
                        });

                        this.todoListData = todo;
                    } else {
                        $('.ajax-overlay').addClass('d-none');
                    }
                }

            },
            saveTodo:function(id){
                let vm =this;
                $('.ajax-overlay').removeClass('d-none');
                let applicationId = this.request_id;
                let completeStatus=0;
                let formdata = ($("#edit_todo_form_"+id).serialize() + "&complete=" + completeStatus);

                // console.log("todo id from variable" + todo_id);
                $.ajax({
                    url: '/'+ applicationId +'/todo' ,
                    type: 'POST',
                    headers: {'X-CSRF-TOKEN': window.appConfig.csrfToken},
                    data: formdata,
                    dataType:'HTML',
                }).done(function (response) {
                    console.log(response);

                    $('.ajax-overlay').addClass('d-none');
                }).fail(function (error) {
                    //console.log(error);
                    $('.ajax-overlay').addClass('d-none');
                });
            },
        }
    };
</script>

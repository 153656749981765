'use strict';
jQuery(function ($) {
    var $addMemberForm = $('form#addMemberForm');
    var $addMemberSubmit = $addMemberForm.find('#add_member');

    var xhrInstance = null;
    $addMemberSubmit.on('click', function (e) {
        console.log('invite member');
        $addMemberSubmit.prop('disabled', true);

        $('#name_group').removeClass('form-error');
        $('#role_group').removeClass('form-error');
        $('#email_group').removeClass('form-error');
        $('#nameError').html('');
        $('#nameError').html('');
        $('#nameError').html('');

        var serializeStr = $addMemberForm.serialize();
        $.post($addMemberForm.prop('action'), serializeStr)
            .done(function (response) {
                console.log(response);

                $addMemberForm.get(0).reset();
                $addMemberSubmit.prop('disabled', false);

                setTimeout(function () {
                    $('#addMember').modal('hide');
                    $('#invite_success').modal('show');
                }, 200);
            })
            .fail(function (jqXHR, textStatus) {
                $addMemberSubmit.prop('disabled', false);

                var errResponse = JSON.parse(jqXHR.responseText);
                console.log(errResponse);

                if (errResponse['errors']['name']) {
                    $('#name_group').addClass('form-error');
                    $('#nameError').html(errResponse['errors']['name'][0]);
                }
                if (errResponse['errors']['role']) {
                    $('#role_group').addClass('form-error');
                    $('#roleError').html(errResponse['errors']['role'][0]);
                }
                if (errResponse['errors']['email']) {
                    $('#email_group').addClass('form-error');
                    $('#emailError').html(errResponse['errors']['email'][0]);
                }
            })
            .always(function (jqXHR) {
                console.log("ajax called");
                //$ajaxLoaderModal.modal('hide');
            });

    });

    //member list page (member.blade) page
    $(document).ready(function () {

        $('#delete_request').on('show.bs.modal', function (e) {
            $(this).find('#delete_user').data('userId', $(e.relatedTarget).data('userId'));
        });

        $('#delete_request').on('click', '#delete_user', function (e) {
            //send value by ajax to server
            $.ajax({
                url: route('church.members.status', [window.appConfig.subDomain, $(this).data('userId')]).url(),
                type: 'POST',
                headers: {'X-CSRF-TOKEN': window.appConfig.csrfToken},
            }).done(function (response) {
                console.log(response);
                window.location.reload(true);
            }).fail(function (error) {
                console.log(error);
            })
        })
    });
    $('#cancel_invitation_request').on('shown.bs.modal', function (e) {
        $(this).find('#delete_user_invite').attr('action', $(e.relatedTarget).data('href'));
    });
    $('#resend_invitation_request').on('shown.bs.modal', function (e) {
        $(this).find('#resend_user_invite').attr('action', $(e.relatedTarget).data('href'));
    });
    $('#delete_user').on('shown.bs.modal', function (e) {
        $(this).find('#delete_user_form').attr('action', $(e.relatedTarget).data('href'));
    });
});

$('#addMember').on('hidden.bs.modal', function () {
    $('#name_group').removeClass('form-error');
    $('#role_group').removeClass('form-error');
    $('#email_group').removeClass('form-error');
    $('#nameError').html('');
    $('#nameError').html('');
    $('#nameError').html('');
    $("#role").val('');
    $("#email").val('');
    $("#name").val('');
});

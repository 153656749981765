<template>
    <div :class="taskDetail.status === 1 ?  'request-desc-wrapper task-desc slide task-completed': 'request-desc-wrapper task-desc slide'">
        <div class="top-options">
            <button @click="close" type="button" class="btn close">
                <i class="fas fa-times"></i>
            </button>
        </div>
        <div class="desc-section mt-2">
            <label class="font-weight-bold">Title</label>
            <input :disabled="(taskDetail.status === 1 || !canEdit)" @input="debounceTitle(taskDetail)" type="text"
                   v-model="taskDetail.title" class="form-control">
            <div class="notes my-3">
                <label class="font-weight-bold">Note</label>
                <textarea :disabled="taskDetail.status === 1 || !canEdit" @input="debounceNote(taskDetail)"
                          :maxlength="maxLengthLimit" v-model="taskDetail.note" class="form-control"
                          rows="4"></textarea>
                <span class="char-count" v-if="!taskDetail.status && canEdit">{{remainCharCount(taskDetail.note, maxLengthLimit)}}</span>
            </div>
            <div class="d-flex align-items-center">
                <div class="mr-4">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="510px" height="510px"
                         viewBox="0 0 510 510">
                        <g id="account-circle">
                            <path
                                d="M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z M255,76.5
                                          c43.35,0,76.5,33.15,76.5,76.5s-33.15,76.5-76.5,76.5c-43.35,0-76.5-33.15-76.5-76.5S211.65,76.5,255,76.5z M255,438.6
                                          c-63.75,0-119.85-33.149-153-81.6c0-51,102-79.05,153-79.05S408,306,408,357C374.85,405.45,318.75,438.6,255,438.6z"/>
                        </g>
                    </svg>
                    <span v-if="taskDetail.created_users_id !== nullValue">By {{ getUserName }}</span>
                    <span v-else>By </span>
                </div>
                <div class="assign-to mr-4">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="510px" height="510px"
                         viewBox="0 0 510 510">
                        <g id="account-circle">
                            <path
                                d="M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z M255,76.5
                                                            c43.35,0,76.5,33.15,76.5,76.5s-33.15,76.5-76.5,76.5c-43.35,0-76.5-33.15-76.5-76.5S211.65,76.5,255,76.5z M255,438.6
                                                            c-63.75,0-119.85-33.149-153-81.6c0-51,102-79.05,153-79.05S408,306,408,357C374.85,405.45,318.75,438.6,255,438.6z"/>
                        </g>
                    </svg>
                    <span>To</span>
                    <span>
                        <select :disabled="taskDetail.status === 1 || !canEdit" v-model="taskDetail.assinged_user_id"
                                @change="updateTodoAssignee(taskDetail.assinged_user_id,taskDetail)">
                            <option :value="nullValue" disabled>Select User</option>
                            <option v-for="user in churchUsers" :value="user.id">{{ user.name }}</option>
                        </select>
                    </span>
                </div>
                <div class="due-date mr-4">
                    <vuejs-datepicker :disabledDates="disabledDates" class="form-control" placeholder="Set Due Date"
                                      :disabled="(taskDetail.status === 1 || !canEdit)"
                                      v-model="taskDetail.actual_end_date" @input="updateDueDate(taskDetail)">
                    </vuejs-datepicker>
                </div>

                <div class="button-wrap">
                    <button class="btn btn-blue ml-0" @click="markTodoComplete(taskDetail)" v-if="!taskDetail.status">
                        Mark Complete
                    </button>
                    <button class="btn btn-blue ml-0 disabled" @click="markTodoComplete(taskDetail)" v-else>
                        Re-Open
                    </button>
                </div>
                <div v-if="canEdit" class="button-wrap delete-todo" @click="enableDeleteModal">
                    <i class="fas fa-trash-alt"></i>
                </div>
            </div>
        </div>

        <div class="wrapper see-more">
            <button class="filter-label reqDetails" type="button" data-toggle="collapse" data-target="#reqDetails"
                    aria-expanded="false">
                <i class="fas fa-caret-right"></i>
                <span>Request Details</span>
            </button>
            <div class="collapse" id="reqDetails">
                <div class="filter-label-content">
                    <div class="req-data">
                        <h5>Extra Information</h5>
                        <div data-type="text" class="box">
                            {{ applicationDetail.extra_information ? applicationDetail.extra_information : "NA" }}
                        </div>
                    </div>
                    <div class="req-data" v-for="detail in JSON.parse(applicationDetail.setting)">
                        <h5>{{ detail.label }}</h5>
                        <div class="box" data-type="text" v-for="value in getChosenValues(detail)">
                            {{ value }}
                        </div>
                    </div>
                    <div class="req-data">
                        <div class="box" data-type="text" v-for="file in applicationDetail.application_detail">
                            <a :href="'/account/file?name=' + file.attachment + '&type=d'">Download file</a>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <div class="alert alert-success position-fixed fixed-bottom" v-if="showSuccessMessage">
            {{ successMessage }}
        </div>
        <delete-task v-if="showDeleteModal" @cancel-delete="disableDeleteModal" @delete-todo="deleteTask"></delete-task>
    </div>
</template>

<script>
    export default {
        name: "TaskDetail",
        props: ["applicationDetail", "taskDetail", "churchUsers", "canEdit"],
        data() {
            return {
                appSetting: "",
                users: "",
                showSuccessMessage: false,
                successMessage: "",
                status: "Mark Complete",
                nullValue: null,
                maxLengthLimit: 500,
                debounce: null,
                disabledDates: {
                    to: {}
                },
                showDeleteModal: false,
            };
        },
        created() {
            var dateObj = new Date();
            dateObj.setDate(dateObj.getDate() - 1);
            this.disabledDates.to = dateObj;
        },
        computed: {
            getUserName() {
                const [createdUser] = this.$props.churchUsers.filter(user => user.id === this.$props.taskDetail.created_users_id);
                this.showDeleteModal = false;
                return createdUser.name;
            },
        },
        methods: {
            deleteTask(){
                this.showDeleteModal = false;
                this.$emit('destroy-todo', this.$props.taskDetail);
            },
            enableDeleteModal() {
              this.showDeleteModal = true;
            },
            disableDeleteModal() {
              this.showDeleteModal = false;
            },
            debounceNote(taskDetail) {
                clearTimeout(this.debounce);
                this.debounce = setTimeout(() => {
                    this.$emit("update-task-note", taskDetail);
                }, 1500);
            },
            debounceTitle(taskDetail) {
                clearTimeout(this.debounce);
                this.debounce = setTimeout(() => {
                    this.$emit("update-task-title", taskDetail);
                }, 1500);
            },
            remainCharCount(textValue, textLimit) {
                let remainCharactersText = "";
                if (textValue) {
                    if (textValue.length > textLimit) {
                        remainCharactersText = textLimit;
                    } else {
                        remainCharactersText = textLimit - textValue.length;
                    }
                } else {
                    remainCharactersText = textLimit;
                }
                return remainCharactersText + " characters left";
            },
            markTodoComplete(task) {
                this.$emit("update-task-status", task);
            },
            updateTodoAssignee(assigneeId, task) {
                this.$emit("update-task-assignee", assigneeId, task);
            },
            updateDueDate(task) {
                this.$emit("update-due-date", task);
            },
            close() {
                this.$emit("close-task-detail-modal");
            },
            isgreater: function (actualDate) {
                let newDate = new Date();
                let actual = new Date(actualDate);
                return actual < newDate;
            },
            format_date(value) {
                if (value) {
                    return moment(String(value)).format("DD MMM YYYY");
                }
            },
            getChosenValues(formValues) {
                let result = [];
                if (formValues.type === "select") {
                    if (formValues.userData !== undefined) {
                        if (Object.keys(formValues.userData).length !== 0) {
                            for (let i = 0; i < formValues.values.length; i++) {
                                for (
                                    let j = 0;
                                    j < formValues.userData.length;
                                    j++
                                ) {
                                    if (
                                        Object.keys(formValues.values).length !== 0
                                    ) {
                                        if (
                                            formValues.userData[j] ===
                                            formValues.values[i].value
                                        ) {
                                            result[j] = formValues.values[i].label;
                                        }
                                    } else {
                                        result[0] = "N/A";
                                    }
                                }
                            }
                            return result;
                        } else {
                            result[0] = "N/A";
                            return result;
                        }
                    } else {
                        result[0] = "N/A";
                        return result;
                    }
                } else if (formValues.type === "checkbox-group") {
                    if (formValues.userData !== undefined) {
                        if (Object.keys(formValues.userData).length !== 0) {
                            for (let i = 0; i < formValues.values.length; i++) {
                                for (
                                    let j = 0;
                                    j < formValues.userData.length;
                                    j++
                                ) {
                                    if (
                                        Object.keys(formValues.values).length !== 0
                                    ) {
                                        if (
                                            formValues.userData[j] ===
                                            formValues.values[i].value
                                        ) {
                                            result[j] = formValues.values[i].label;
                                        }
                                    } else {
                                        result[0] = "N/A";
                                    }
                                }
                            }
                            return result;
                        } else {
                            result[0] = "N/A";
                            return result;
                        }
                    } else {
                        result[0] = "N/A";
                        return result;
                    }
                } else if (formValues.type === "radio-group") {
                    if (formValues.userData !== undefined) {
                        if (Object.keys(formValues.userData).length !== 0) {
                            for (let i = 0; i < formValues.values.length; i++) {
                                for (
                                    let j = 0;
                                    j < formValues.userData.length;
                                    j++
                                ) {
                                    if (
                                        Object.keys(formValues.values).length !== 0
                                    ) {
                                        if (
                                            formValues.userData[j] ===
                                            formValues.values[i].value
                                        ) {
                                            result[j] = formValues.values[i].label;
                                        }
                                    } else {
                                        result[0] = "N/A";
                                    }
                                }
                            }
                            return result;
                        } else {
                            result[0] = "N/A";
                            return result;
                        }
                    } else {
                        result[0] = "N/A";
                        return result;
                    }
                } else {
                    if (formValues.userData !== "") {
                        return formValues.userData;
                    } else {
                        result[0] = "N/A";
                        return result;
                    }
                }
            }
        }
    };
</script>

<style scoped></style>

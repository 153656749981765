<template>
    <aside class="sidebar-right collapse show create-new-todo">
        <div class="d-flex justify-content-between align-items-center pb-3">
            <h2 class="h2 mb-0">Create a New Task</h2>
            <button class="btn-close btn btn-transparent border-0" @click="close">
                <i class="fa fa-times"></i>
            </button>
        </div>
        <div class="form-group">
            <label class="font-weight-bold"> Title</label>
            <input v-model="requestName" type="text" class="form-control" placeholder="Add Todo Title" required/>
            <span v-if="errors.hasOwnProperty('requestName')" class="field-text text-danger">
                {{ errors.requestName }}
            </span>
        </div>
        <div class="form-group task-assign">
            <label>Assign To</label>
            <select class="form-control" v-model="assignedUser" required>
                <option value="" disabled>Select User</option>
                <option v-for="user in users" :value="user.id">{{user.name}}</option>
            </select>
            <span v-if="errors.hasOwnProperty('assignedUser')" class="field-text text-danger">
                {{ errors.assignedUser }}
            </span>
        </div>
        <div class="form-group">
            <label>Due Date</label>
            <div class="due-date">
                <vuejs-datepicker :disabledDates="disabledDates" v-model="dueDate" placeholder="Select Due Date" required></vuejs-datepicker>
            </div>
            <span v-if="errors.hasOwnProperty('dueDate')" class="field-text text-danger">{{ errors.dueDate }}</span>
        </div>
        <div class="form-group task-assign">
            <label>Assign To Request</label>
            <select class="form-control" v-model="requestId" required>
                <option value="" disabled>Select Request</option>
                <option v-for="request in requests" :value="request.id">{{request.name}}</option>
            </select>
            <span v-if="errors.hasOwnProperty('requestId')" class="field-text text-danger">{{ errors.requestId }}</span>
        </div>
        <div class="form-group">
            <label class="font-weight-bold">Notes</label>
            <textarea :maxlength="maxLengthLimit" name="" class="form-control" v-model="note" placeholder="Add Note"></textarea>
            <span class="char-count" v-if="note">
                {{remainCharCount(note, maxLengthLimit)}}
            </span>
        </div>

        <button class="btn btn-blue mt-2 ml-0 px-4 px-lg-5 py-2" @click="addTodo">Submit</button>
        <div class="alert alert-success position-fixed fixed-bottom" v-if="showSuccessMessage">
            {{ successMessage }}
        </div>
    </aside>
</template>

<script>
export default {
    name: "CreateTask",
    props: ["users", "requests"],
    data() {
        return {
            errors: {},
            requestName: "",
            assignedUser: "",
            dueDate: "",
            requestId: "",
            note: "",
            showSuccessMessage: false,
            maxLengthLimit: 500,
            disabledDates: {
                to: {}
            }
        };
    },
    created() {
        var dateObj = new Date();
        dateObj.setDate(dateObj.getDate() - 1);
        this.disabledDates.to = dateObj;
    },
    methods: {
        remainCharCount(textValue, textLimit) {
            let remainCharactersText = "";
            if (textValue) {
                if (textValue.length > textLimit) {
                    remainCharactersText = textLimit;
                } else {
                    remainCharactersText = textLimit - textValue.length;
                }
            } else {
                remainCharactersText = textLimit;
            }
            return remainCharactersText + " characters left";
        },
        validateForm() {
            this.errors = {};
            let isTrue = true;
            if (this.requestName === "") {
                this.errors.requestName = "Please add a title.";
                isTrue = false;
            }
            if (this.assignedUser === "") {
                this.errors.assignedUser = "Please select a user.";
                isTrue = false;
            }
            if (this.dueDate === "") {
                this.errors.dueDate = "Please select a due date.";
                isTrue = false;
            }
            if (this.requestId === "") {
                this.errors.requestId = "Please select a request.";
                isTrue = false;
            }
            return isTrue;
        },
        close() {
            this.$emit("close-create-task");
        },
        closeTask(successMessage) {
            this.$emit("refresh-tasks", 1, successMessage);
        },
        addTodo() {
            let vm = this;
            if (vm.validateForm()) {
                const date = vm.dueDate.getDate();
                const month = vm.dueDate.getMonth() + 1;
                const year = vm.dueDate.getFullYear();
                const fullDate = `${year}-${month}-${date}`;
                $(".ajax-overlay").removeClass("d-none");
                $.ajaxSetup({
                    headers: {
                        "X-CSRF-TOKEN": $('meta[name="csrf-token"]').attr(
                            "content"
                        )
                    }
                });
                $.ajax({
                    type: "POST",
                    url: "/" + vm.requestId + "/todo",
                    data: {
                        requestName: vm.requestName,
                        assignedUser: vm.assignedUser,
                        requestId: vm.requestId,
                        note: vm.note,
                        dueDate: fullDate
                    },
                    success: data => {
                        $(".ajax-overlay").addClass("d-none");
                        this.successMessage = data.success;
                        this.showSuccessMessage = true;
                        this.closeTask(data.success);
                    }
                });
            }
        }
    }
};
</script>

<style scoped></style>

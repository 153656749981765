'use strict';

import $ from 'jquery';
window.$ = window.jQuery = $;
require('./bootstrap');

require('./sb-admin-2');
require('formBuilder');
require('formBuilder/dist/form-render.min');
require('parsleyjs');
//require('jquery');

require('jquery-ui');
require('jquery-ui/ui/widgets/datepicker');
require('jquery-ui-sortable');

//require('bootstrap-select-v4');

require('bootstrap-select');
require('select2');
window.moment = require('moment');
window.topbar = require('topbar');
require('./custom/register');
require('./custom/subscription/subscription');
require('./custom/member/invite');
require('./custom/form/form');
require('./custom/user/billing');
require('./custom/application/application');
require('./custom/todo/todo');
require('./custom/application/message');
require('./custom/notification/notification');
require('./custom/admin/header_tracking/index_script');

import Vue from 'vue';
import Datepicker from 'vuejs-datepicker';
import ParentModal from "./components/ParentModal";
import TodoModal from "./components/RequestFormTodo";
import ViewModal from "./components/RequestFormView";
import MessageModal from "./components/Messages";
import RequestDetail from "./components/RequestDetail";
import StatusDropdown from "./components/StatusDropdown";
import TasksListing from "./components/TasksListing"

import CalendarComponent from "./components/CalendarComponent";
import RequestTags from "./components/RequestTags";
import TaskDetail from "./components/TaskDetail";
import CreateTask from "./components/CreateTask";
import DeleteTask from "./components/modals/DeleteTask";

/**
 * Calling Vue Component
 */

Vue.component('notifications-settings-component', require('./components/NotificationsSettingsComponent.vue').default);
Vue.component('parent-modal', ParentModal);
Vue.component('todo-modal', TodoModal);
Vue.component('view-modal', ViewModal);
Vue.component('message-modal', MessageModal);
Vue.component('request-detail', RequestDetail);
Vue.component('request-tags', RequestTags);
Vue.component('status-dropdown', StatusDropdown);
Vue.component('pagination', require('laravel-vue-pagination'));
Vue.component('calendar-component', CalendarComponent);
Vue.component('tasks-listing', TasksListing);
Vue.component('task-detail', TaskDetail);
Vue.component('create-task', CreateTask);
Vue.component('delete-task', DeleteTask);
Vue.component('vuejs-datepicker', Datepicker);

export const eventBus = new Vue();

let app = new Vue({
    el: "#app",
});

(function ($) {
    $('.notificationsDropdown').on('show.bs.dropdown', function (e) {
        topbar.show();
        $('#newNotificationIndicator').remove();
        $.ajax({
            url: route('account.notification.read', [window.appConfig.subDomain]).url(),
            type: 'POST',
            headers: {'X-CSRF-TOKEN': window.appConfig.csrfToken},
        }).done(function (response) {
            console.log(response);
            topbar.hide();
            //window.location.reload(true);
        }).fail(function (error) {
            console.log(error);
            topbar.hide();
        })
    });
})(jQuery); // End of use strict

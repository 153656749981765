<template>
    <div class="request-desc-wrapper slide">
        <div class="top-options">
            <div class="dropdown">
                <!-- dropdown option -->
                <button type="button" class="btn no-arrow" role="button" data-toggle="dropdown" aria-haspopup="true"
                        aria-expanded="false">
                    <i class="fas fa-ellipsis-h"></i>
                </button>
                <div class="dropdown-menu dropdown-menu-right animated--fade-in">
                    <div class="dropdown-divider"></div>
                    <div class="dropdown-item bold" id="editRequest" @click="editForm(JSON.parse(userData.setting))">
                        Edit Request
                    </div>
                </div>
            </div>
            <!-- close button -->
            <button type="button" class="btn close" @click="close()"><i class="fas fa-times"></i></button>
        </div>
        <!-- ====== request data ======  -->
        <div class="desc-section">
            <h4 class="title">{{userData.name}}</h4>
            <div class="d-flex align-items-center">
                <div class="with-tooltip mr-4">
                    <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="510px" height="510px"
                         viewBox="0 0 510 510">
                        <g id="account-circle">
                            <path d="M255,0C114.75,0,0,114.75,0,255s114.75,255,255,255s255-114.75,255-255S395.25,0,255,0z M255,76.5
                          c43.35,0,76.5,33.15,76.5,76.5s-33.15,76.5-76.5,76.5c-43.35,0-76.5-33.15-76.5-76.5S211.65,76.5,255,76.5z M255,438.6
                          c-63.75,0-119.85-33.149-153-81.6c0-51,102-79.05,153-79.05S408,306,408,357C374.85,405.45,318.75,438.6,255,438.6z"/>
                        </g>
                    </svg>
                    <span>By {{userData.user.name}}</span>
                    <div class="tooltip text-white">
                        <p>This request is by John Doe.</p>
                    </div>
                </div>
                <div class="with-tooltip due-date">
                    <input type="text" id="requestDatepicker2"
                           v-bind:class="{'due_datepicker':true, 'passed-due-date': isgreater(userData.request_due_date)}"
                           :value="format_date(userData.request_due_date)" readonly="readonly">
                    <input type="hidden" id="requestDatepicker2_hidden" name="request_due_date">
                    <div class="tooltip">
                        <span>Due Date</span>
                        <hr>
                        <p>The assignee will get notifications starting a week before this task is due.</p>
                    </div>

                </div>
                <div class="with-tooltip request-status mr-1">
                    <select v-model="userData.status" :disabled="isRequester" :status="userData.status" class="selectpicker"
                            v-on:change="updateStatus($event,userData.id)">
                        <option v-for="(key, statusOption) in statusData" :value="statusOption" :key="statusOption">
                            {{key}}
                        </option>
                    </select>

                    <div class="tooltip">
                        <span>Request Status</span>
                        <hr>
                        <p>The status of the request submitted.</p>
                    </div>
                </div>
                <div class="with-tooltip request-assign" :data="userData.assigned_to">
                    <select v-model="userData.assigned_to" :disabled="isRequester" v-on:change="updateManager($event,userData.id)"
                            title="Select Approval Manager" class="selectpicker" data-live-search="true">
                        <option v-for="managerOption in approvalManager" :value="managerOption.id"
                                :key="managerOption.id">
                            {{managerOption.name}}
                        </option>
                    </select>
                    <div class="tooltip">
                        <span>Select Approval Manager</span>
                        <hr>
                        <p>The Approval Manager is the one who'll approve the request submitted.</p>
                    </div>
                </div>
            </div>
            <div v-if="message" class="alert alert-success">{{ message }}</div>
        </div>

        <!-- ====== add-tag section ======  -->
        <div id="tags-section" class="tags-section">
            <div id="tagcontainer">
                <form id="edit_tag_form_12" class="add-tag-form align-items-center position-relative">

                    <request-tags :request_id="userData.id" ref="tagChild"></request-tags>
                </form>
            </div>
        </div>

<!--        <todo-modal v-bind:request_id="userData.id" :approvalManager="approvalManager"-->
<!--                    ref="childComponent"></todo-modal>-->
        <div class="wrapper see-more">
            <button class="filter-label reqDetails bg-transparent" type="button" data-toggle="collapse"
                    data-target="#reqDetails" aria-expanded="false">
                <i class="fas fa-caret-right"></i>
                <span>Request Details</span>
            </button>
            <!-- edit request data -->
            <button v-show="isEdit==false" class="btn with-tooltip edit-data"
                    @click="editForm(JSON.parse(userData.setting))">
                <svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="528.899px" height="528.899px"
                     viewBox="0 0 528.899 528.899">
                    <g>
                        <path d="M328.883,89.125l107.59,107.589l-272.34,272.34L56.604,361.465L328.883,89.125z M518.113,63.177l-47.981-47.981
                        c-18.543-18.543-48.653-18.543-67.259,0l-45.961,45.961l107.59,107.59l53.611-53.611
                        C532.495,100.753,532.495,77.559,518.113,63.177z M0.3,512.69c-1.958,8.812,5.998,16.708,14.811,14.565l119.891-29.069
                        L27.473,390.597L0.3,512.69z"/>
                    </g>
                </svg>
                <div class="tooltip">Edit request details.</div>
            </button>
            <i @click="showCancel" v-show="isEdit==true" class="fas fa-times cursor-pointer"></i>
            <!-- /edit request data -->
            <div class="collapse" id="reqDetails">
                <div class="filter-label-content">
                    <div class="req-data" v-show="isEdit==false">
                        <h5>Extra Information</h5>
                        <div data-type="text" class="box">
                            {{userData.extra_information ? userData.extra_information : 'NA'}}
                        </div>
                    </div>
               `     <div class="req-data" v-show="isEdit==false" v-for="userDetail in JSON.parse(userData.setting)">
                        <h5>{{userDetail.label}}</h5>
                        <div class="box" data-type="text" v-for="val in getChosenValues(userDetail)">
                            {{val}}
                        </div>
                    </div>
                    <div class="req-data" v-show="isEdit==false">
                        <div class="box" data-type="text" v-for="file in userData.application_detail">
                            <a :href="'/account/file?name=' + file.attachment + '&type=d'">Download file</a>
                        </div>
                    </div>

                </div>
                <div class="req-data" v-show="isEdit==true">
                    <form enctype="multipart/form-data" name="create-request-form" id="create-request-form">
                        <div class="form-group field-text-1553606083396">
                            <label for="application_name" class="fb-text-label">Request</label>
                            <input name="name" type="text" :value="userData.name" class="form-control" maxlength="100"
                                   id="application_name" @keyup="checkEmpty()">
                        </div>
                        <div class="form-group field-text-1553606083397">
                            <label for="text-1553606083397" class="fb-text-label">Extra Information</label>
                            <input name="extra_information" type="text" :value="userData.extra_information"
                                   class="form-control" maxlength="100" id="text-1553606083397">
                        </div>
                        <input type="hidden" name="setting" id="setting" v-model="userData.setting">

                        <div id="church_form_render"></div>
                        <div class="form-group">
                            <button id="updateRequest" type="button" @click="saveApplication()" name="updateRequest"
                                    class="btn btn-trigger mr-4">Update Request
                            </button>
                        </div>
                    </form>
                </div>
            </div>


        </div>
        <!-- /request details wrapper ====== -->
        <message-modal v-bind:request_id="userData.id"></message-modal>
    </div>
</template>
<script>

    export default {
        name: 'RequestDetail',
        props: ['userData'],
        data() {
            return {
                pageOfItems: [],
                todosList: [],
                isEdit: false,
                statusData: {},
                approvalManager: {},
                isRequester: false,
                message:'',
            };
        },
        mounted() {
            let vm = this;
            this.isRequester = this.$attrs.isRequester;
            this.statusData = this.$attrs.statusData;
            this.approvalManager = this.$attrs.approvalManager;
            $(".selectpicker").selectpicker();
            $('.due_datepicker').datepicker({
                dateFormat: "d M yy",
                firstDay: 1,
                onSelect: function (dateText) {
                    var selectedDate = $(this).datepicker('getDate');
                    var duedateId = $(this).attr("id");
                    $("#" + duedateId + '_hidden').val($.datepicker.formatDate("yy-mm-dd", selectedDate));
                    $("#" + duedateId).val(dateText);
                    var now = new Date();
                    now.setHours(0, 0, 0, 0);
                    $(this).parent().addClass("date-selected");
                    if (selectedDate < now) {
                        $(this).addClass('passed-due-date');
                    } else {
                        $(this).removeClass('passed-due-date');
                    }
                    vm.saveDueDate();
                }
            });
            this.checkEmpty();
            this.$nextTick(()=>{
                this.$forceUpdate();
            });
        },
        updated() {
            $(".selectpicker").selectpicker('refresh');
        },
        methods: {
            checkEmpty(){
                if($("#application_name").val().length > 0) {
                    $("#updateRequest").removeAttr('disabled');
                } else {
                    $("#updateRequest").attr("disabled", "disabled");
                }
            },
            tagSetFocus(){
                //this.$refs.tagChild.$refs.inputTag.focus();
            },
            isgreater: function (actualDate) {
                let newDate = new Date();
                let actual = new Date(actualDate);
                return actual < newDate;
            },
            format_date(value) {
                if (value) {
                    return moment(String(value)).format('DD MMM YYYY');
                }
            },
            updateManager(event, id) {
                this.$parent.updateManager(event, id);
            },
            updateStatus(event, id) {
                this.$parent.updateApproval(event, id);
            },
            click: function () {
                this.$refs.childComponent.addTodo();
            },
            todoForm() {
                let applicationId = this.userData.id;
                axios.get(route('todo.create', [applicationId]).url())
                    .then(response => {
                        this.todosList = [...response.data].slice(0, 10);
                    })

            },
            onChangePage(pageOfItems) {
                // update page of items
                this.pageOfItems = pageOfItems;
            },
            close() {
                this.$emit('close');
            },
            showCancel() {
                this.isEdit = false;
            },
            editForm(formValues) {
                this.isEdit = true;
                var options = {
                    formData: formValues || '',
                    dataType: 'json',
                    disableFields: ['autocomplete', 'hidden', 'header', 'number', 'paragraph', 'button'],
                    roles: false,
                    showActionButtons: false,
                    fieldRemoveWarn: true, // or defaults to false
                    disableHTMLLabels: true,
                    disabledSubtypes: {
                        text: ['password', 'color'],
                        textarea: ['tinymce', 'quill'],
                        file: ['fineuploader'],
                    },
                    disabledAttrs: [
                        'access', 'description', 'inline', 'max', 'other', 'step', 'toggle', 'className', 'name'
                    ]
                };
                let formBuilder = $('#church_form_render').formRender(options);

                $('#reqDetails').addClass('show');
                let t = $('#reqDetails').prev().prev().prev().attr('aria-expanded', true);
            },
            saveApplication() {
                this.isEdit = false;
                $('.ajax-overlay').removeClass('d-none');
                $('#setting').val(window.JSON.stringify($('#church_form_render').formRender("userData")));
                this.userData.setting = $('#setting').val();
                let $form = $('form#create-request-form');
                var formdata = new FormData($form[0]);
                if ($form.parsley().validate()) {
                    $.ajax({
                        url: '/church/' + this.userData.church_id + '/application/' + this.userData.id + '/request/updateRequest',
                        type: 'post',
                        headers: {'X-CSRF-TOKEN': window.appConfig.csrfToken},
                        data: formdata,
                        cache: false,
                        processData: false,
                        contentType: false,
                    }).done(function (response) {
                        $('.ajax-overlay').addClass('d-none');
                    }).fail(function (error) {
                    });
                }
            },
            saveDueDate() {
                let vm = this;
                let dueDate = $("#requestDatepicker2_hidden").val();
                $.ajax({
                    url: '/church/' + this.userData.church_id + '/application/' + this.userData.id + '/request/updateRequestDueDate',
                    type: 'POST',
                    headers: {'X-CSRF-TOKEN': window.appConfig.csrfToken},
                    data: "request_due_date=" + dueDate,
                    dataType: 'HTML',
                }).done(function (response) {
                    vm.userData.request_due_date = dueDate;
                    $('.ajax-overlay').addClass('d-none');
                    vm.message="Date Updated Successfully!";
                    setTimeout(function(){
                        vm.message='';
                    },2000);
                    vm.$parent.reloadComponent();
                    console.log(response);
                }).fail(function (error) {
                });
            },
            getChosenValues(formValues) {
                let result = [];
                if (formValues.type === "select") {
                    if (formValues.userData !== undefined) {
                        if (Object.keys(formValues.userData).length !== 0) {
                            for (let i = 0; i < formValues.values.length; i++) {
                                for (let j = 0; j < formValues.userData.length; j++) {
                                    if (Object.keys(formValues.values).length !== 0) {
                                        if (formValues.userData[j] === formValues.values[i].value) {
                                            result[j] = formValues.values[i].label;
                                        }
                                    } else {
                                        result[0] = 'N/A';
                                    }
                                }
                            }
                            return result;
                        } else {
                            result[0] = 'N/A';
                            return result;
                        }
                    } else {
                        result[0] = 'N/A';
                        return result;
                    }
                } else if (formValues.type === "checkbox-group") {
                    if (formValues.userData !== undefined) {
                        if (Object.keys(formValues.userData).length !== 0) {
                            for (let i = 0; i < formValues.values.length; i++) {
                                for (let j = 0; j < formValues.userData.length; j++) {
                                    if (Object.keys(formValues.values).length !== 0) {
                                        if (formValues.userData[j] === formValues.values[i].value) {
                                            result[j] = formValues.values[i].label;
                                        }
                                    } else {
                                        result[0] = 'N/A';
                                    }
                                }
                            }
                            return result;
                        } else {
                            result[0] = 'N/A';
                            return result;
                        }
                    } else {
                        result[0] = 'N/A';
                        return result;
                    }

                } else if (formValues.type === "radio-group") {
                    if (formValues.userData !== undefined) {
                        if (Object.keys(formValues.userData).length !== 0) {
                            for (let i = 0; i < formValues.values.length; i++) {
                                for (let j = 0; j < formValues.userData.length; j++) {
                                    if (Object.keys(formValues.values).length !== 0) {
                                        if (formValues.userData[j] === formValues.values[i].value) {
                                            result[j] = formValues.values[i].label;
                                        }
                                    } else {
                                        result[0] = 'N/A';
                                    }
                                }
                            }
                            return result;
                        } else {
                            result[0] = 'N/A';
                            return result;
                        }
                    } else {
                        result[0] = 'N/A';
                        return result;
                    }
                } else {
                    if (formValues.userData !== '') {
                        return formValues.userData;
                    } else {
                        result[0] = 'N/A';
                        return result;
                    }
                }
            }
        }
    };
</script>
